import DragNDropWrapper from '@atom/DragNDropWrapper';
import { updateRawData } from '@utils/index';
import update from 'immutability-helper';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import MealOrderCard from './MealOrderCard';
import MealSubcategores from './MealSubcategories';

const MealOrder = () => {
  const { mealOrders, activeAccordion } = useSelector((state: any) => state.rawData);

  const isParentDraggable = activeAccordion?.length === 0

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
        const res = update(mealOrders, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, mealOrders[dragIndex]]
          ]
        });
        const filteredArray = res?.filter((item: undefined) => item !== undefined);
        updateRawData({ mealOrders: filteredArray });
    },
    [mealOrders]
  );

  return isParentDraggable
    ? mealOrders?.map((meal: any, mealIndex: number) => (
        <DragNDropWrapper
          key={mealIndex}
          index={mealIndex}
          canDrag={isParentDraggable}
          isSide={true}
          moveCard={moveCard}
        >
          <MealOrderCard mealIndex={mealIndex} meal={meal?.meal} key={`${mealIndex}-meal`}>
            <MealSubcategores mealIndex={mealIndex} />
          </MealOrderCard>
        </DragNDropWrapper>
      ))
    : mealOrders?.map((meal: any, mealIndex: number) => (
        <MealOrderCard mealIndex={mealIndex} meal={meal?.meal} key={`${mealIndex}-meal`}>
          <MealSubcategores mealIndex={mealIndex} />
        </MealOrderCard>
      ));
};

export default MealOrder;