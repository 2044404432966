import { Col, Row, Spin } from 'antd';
import {
  IAdditionalContacts,
  IInitialFiltersType,
  IVendorDataType,
  IVendorRecordDataType
} from '../types';
import React, { useEffect, useState } from 'react';
import {
  VENDOR_DETAILS_TABS,
  VENDOR_MANAGEMENT_TABS,
  VENDOR_RECORD_TABS,
  changeVendorStatus,
  getButton,
  getVendor,
  getVendorPastRecords,
  getVendorUpcomingRecords,
  initialFilters
} from '../helper';
import { showErrorToast, showToast } from '@utils/index';
import { useLocation, useNavigate, useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import ConfirmModal from '@organism/ConfimationModal';
import EditIcon from '@assets/edit-2.svg';
import FormDataView from '@atom/FormDataView';
import { H2Typography } from '@constant/typography/Typography';
import HorizontalTab from '@molecules/HorizontalTabs';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import VendorDocuments from './VendorDocuments';
import VendorInfo from './VendorInfo';
import VendorRecords from './VendorRecords';
import { getUniqueDomId } from '@fullcalendar/core/internal';
import { useSelector } from 'react-redux';

function VendorDetailsViewPages() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState<boolean>(false);
  const [isReactivateModalVisible, setIsReactivateModalVisible] = useState<boolean>(false);
  const [managementTabsType, setManagementTabsType] = useState(VENDOR_DETAILS_TABS.INFO);
  const [recordTabsType, setRecordTabsType] = useState(VENDOR_RECORD_TABS.UPCOMING_EVENTS);
  const [vendorData, setVendorData] = useState<IVendorDataType>({});
  const [vendorRecordData, setVendorRecordData] = useState();
  const [isLoadingVendorManagement, setIsLoadingVendorManagement] = useState<boolean>(false);
  const [isLoadingVendorRecords, setIsLoadingVendorRecords] = useState<boolean>(false);
  const [filters, setFilters] = useState<IInitialFiltersType>(initialFilters);

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    getVendorData();
    getVendorUpcomingRecordMethod();
  }, [id]);

  const getVendorData = async () => {
    setIsLoadingVendorManagement(true);
    try {
      const res = await getVendor(id);
      if (res?.success) {
        const documentsList = res?.data?.documents?.map((doc: any) => {
          doc.uid = getUniqueDomId();
          doc.name = doc.document_name;
          doc.url = doc.document_url;
          return doc;
        });
        let additionalContacts = [];
        if (res?.data?.additional_contacts?.length > 0) {
          additionalContacts = res?.data?.additional_contacts?.map(
            (contact: IAdditionalContacts) => {
              return {
                ...contact,
                country_code:
                  !!contact?.country_code && !contact?.country_code?.includes('+')
                    ? `+${contact?.country_code}`
                    : contact?.country_code
              };
            }
          );
        }
        const countryCode =
          !!res?.data?.country_code && !res?.data?.country_code?.includes('+')
            ? `+${res?.data?.country_code}`
            : res?.data?.country_code;
        setVendorData(
          {
            ...res?.data,
            documents: documentsList,
            additional_contacts: additionalContacts,
            country_code: countryCode
          }
        );
      }
    } catch (error) {}
    setIsLoadingVendorManagement(false);
  };

  const getVendorUpcomingRecordMethod = async () => {
    if (!id || !filters?.page) return;
    setIsLoadingVendorRecords(true);
    try {
      const newFilters = { ...filters, type: recordTabsType };
      setFilters(newFilters);
      const res = await getVendorUpcomingRecords(id, filters?.page);
      if (res?.success) {
        setVendorRecordData(res?.data);
      }
    } catch (error) {}
    setIsLoadingVendorRecords(false);
  };

  const getVendorPastRecordMethod = async () => {
    if (!id || !filters?.page) return;
    setIsLoadingVendorRecords(true);
    try {
      const newFilters = { ...filters, type: recordTabsType };
      setFilters(newFilters);
      const res = await getVendorPastRecords(id, filters?.page);
      if (res?.success) {
        setVendorRecordData(res?.data);
      }
    } catch (error) {}
    setIsLoadingVendorRecords(false);
  };

  const changeStatusMethod = async (text: 'Deactivated' | 'Reactivated', status: number) => {
    if (!id) return;
    setIsLoadingVendorManagement(true);
    const res = await changeVendorStatus(id, status);
    if (res?.success) {
      setIsReactivateModalVisible(false);
      setIsDeactivateModalVisible(false);
      showToast({ message: 'Success!', description: `Vendor ${text}` });
      navigate(`/vendors/tab/${state}`);
    } else {
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
    setIsLoadingVendorManagement(false);
  };

  const getVendorManagementTabs = () => {
    const tabValues = [
      {
        tab: 'INFO',
        content: <VendorInfo VendorData={vendorData} />,
        key: VENDOR_DETAILS_TABS.INFO,
        getUrl: VENDOR_DETAILS_TABS.INFO
      },
      {
        tab: 'DOCUMENTS',
        content: <VendorDocuments VendorData={vendorData} />,
        key: VENDOR_DETAILS_TABS.DOCUMENTS,
        getUrl: VENDOR_DETAILS_TABS.DOCUMENTS
      }
    ];
    return tabValues;
  };

  const getVendorRecordsTabs = () => {
    const tabValues = [
      {
        tab: 'Upcoming Events',
        content: (
          <VendorRecords
            vendorRecordData={vendorRecordData}
            isLoadingVendorRecords={isLoadingVendorRecords}
            setFilters={setFilters}
          />
        ),
        key: VENDOR_RECORD_TABS.UPCOMING_EVENTS,
        getUrl: VENDOR_RECORD_TABS.UPCOMING_EVENTS
      },
      {
        tab: 'Past Events',
        content: (
          <VendorRecords
            vendorRecordData={vendorRecordData}
            isLoadingVendorRecords={isLoadingVendorRecords}
            setFilters={setFilters}
          />
        ),
        key: VENDOR_RECORD_TABS.PAST_EVENTS,
        getUrl: VENDOR_RECORD_TABS.PAST_EVENTS
      }
    ];
    return tabValues;
  };

  if (isLoadingVendorManagement) {
    return <CenterSpiner />;
  }

  // if (isLoadingVendorRecords) {
  //   return <Spin />;
  // }

  return (
    <>
      <PageLayout title={'Vendors Management'}>
        <PageTitle
          text="VENDOR MANAGEMENT"
          bordered={true}
          isBottom={true}
          isBack={true}
          path={`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`}
        >
          {isAdmin && (
            <>
              {(!!vendorData?.status || vendorData?.status === 0) &&
                getButton(
                  vendorData.status,
                  setIsDeactivateModalVisible,
                  setIsReactivateModalVisible
                )}
              <Button
                variant="ghost"
                text="EDIT"
                icon={<img src={EditIcon} alt="Edit" />}
                onClick={() => navigate(`/vendors/${id}/edit`)}
              />
            </>
          )}
        </PageTitle>
        <Row gutter={24}>
          <Col span={24}>
            <HorizontalTab
              value={getVendorManagementTabs()}
              activeKey={managementTabsType}
              onChange={(tab: any) => {
                setManagementTabsType(tab);
                // let newurl = `/vendors/tab/${tab}`;
                // navigate(newurl);
              }}
            />
          </Col>
        </Row>
        <ConfirmModal
          title="Are you sure you want to Deactivate this Vendor?"
          okText="Deactivate"
          cancelText="Cancel"
          visible={isDeactivateModalVisible}
          onOK={() => changeStatusMethod('Deactivated', 0)}
          onCancel={() => setIsDeactivateModalVisible(false)}
        >
          This Vendor will be tagged as "INACTIVE"
        </ConfirmModal>
        <ConfirmModal
          title="Are you sure you want to Reactivate this Vendor?"
          okText="Reactivate"
          cancelText="Cancel"
          visible={isReactivateModalVisible}
          onOK={() => changeStatusMethod('Reactivated', 1)}
          onCancel={() => setIsReactivateModalVisible(false)}
        >
          This Vendor will moved into the “ACTIVE” Vendor list.
        </ConfirmModal>
      </PageLayout>
      {managementTabsType === VENDOR_DETAILS_TABS.INFO && (
        <PageLayout style={{ fontWeight: '300px !important' }} title={'Vendors Management'}>
          <PageTitle text="VENDOR RECORDS" isBack={false} />
          <Row gutter={24}>
            <Col span={24}>
              <HorizontalTab
                value={getVendorRecordsTabs()}
                activeKey={recordTabsType}
                onChange={(tab: any) => {
                  setRecordTabsType(tab);
                  if (tab === VENDOR_RECORD_TABS.UPCOMING_EVENTS) {
                    getVendorUpcomingRecordMethod();
                  } else {
                    getVendorPastRecordMethod();
                  }
                  // let newurl = `/vendors/tab/${tab}`;
                  // navigate(newurl);
                }}
              />
            </Col>
          </Row>
        </PageLayout>
      )}
    </>
  );
}

export default VendorDetailsViewPages;
