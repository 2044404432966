import { Button } from '@atom/Buttons';
import React, { useEffect, useRef, useState } from 'react';
import { DownOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Col, Row, Select, Spin } from 'antd';
import SelectInput from '@atom/Select';
import { Input } from '@atom/Input';
import { getSpecialIngredientsItem } from './helper';
import ModalComponent from '@organism/Modal/Modal';
import { H4Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { getSpecialIngredientCols } from './columns';
import { SpecialIngredientWraper, TextBox } from '../style';
import { VisibilityOptions } from './Constant';
import SearchableSelect from '@atom/SearchableSelect';

function valid(f: number) {
  if (isNaN(f)) {
    return false;
  }
  return 100 * f == parseInt(String(100 * f), 10);
}

const IngredientRowItem = (obj: any) => {
  const { index, style } = obj;
  const {
    setFieldValue,
    touched,
    errors,
    values,
    handleBlur,
    measurementsData,
    prepListData,
    initialIngredientsList,
    initialSpecialIngredientsList,
    setProductSubmit
  } = obj?.data;

  const [localIngredientName, setLocalIngredientName] = useState<any>('');
  const [localAmount, setLocalAmount] = useState<string>('');
  const [localMeasurement, setLocalMeasurement] = useState<string>('');
  const [localPrepList, setLocalPrepList] = useState<string>('');
  const [localVisibility, setLocalVisibility] = useState<string>('');
  const [specialIngredientsItem, setSpecialIngredientsItem] = useState<any>([]);
  const [ViewSpecialIngredientPopup, setViewSpecialIngredientPopup] = useState<boolean>(false);
  const [filteredIngredientsList, setFilteredIngredientsList] = useState<any>([]);
  const [filteredSpecialIngredientsList, setFilteredSpecialIngredientsList] = useState<any>([]);

  const idBase: string = `product_subcategory.ingredients[${index}]` || '';

  const getBaseObjectForEntity = (baseObject: any) => {
    return baseObject?.product_subcategory?.ingredients?.[index] || {};
  };

  const valVarBase: any = getBaseObjectForEntity(values);
  const touchedVarBase: any = getBaseObjectForEntity(touched);
  const errorVarBase: any = getBaseObjectForEntity(errors);

  useEffect(() => {
    setFilteredIngredientsList(initialIngredientsList || []);
    setFilteredSpecialIngredientsList(initialSpecialIngredientsList || []);
    const ingredientName = valVarBase?.is_special_ingredient
      ? initialSpecialIngredientsList?.find((item: any) => item?.id === valVarBase?.ingredient_id)
          ?.name
      : initialIngredientsList?.find((item: any) => item?.id === valVarBase?.ingredient_id)?.name;

    setLocalIngredientName({
      id: valVarBase?.ingredient_id,
      name: valVarBase?.ingredient_name ? valVarBase?.ingredient_name : ingredientName,
      label: valVarBase?.ingredient_name ? valVarBase?.ingredient_name : ingredientName,
      value: valVarBase?.ingredient_id
    });
    setLocalAmount(valVarBase?.amount);
    setLocalMeasurement(valVarBase?.measurement_id);
    setLocalPrepList(valVarBase?.prep_list_id);
    setLocalVisibility(valVarBase?.visibility);
  }, []);

  const deleteIngredientItemHandler = () => {
    const idBaseForDelete: string = `product_subcategory.ingredients`;
    const valBaseObjectForDelete: any = values?.product_subcategory.ingredients || [];
    const tempValObj = [...JSON.parse(JSON.stringify(valBaseObjectForDelete))];
    tempValObj.splice(index, 1);
    const sortedTempVal = tempValObj?.map((i: any, subIndex: number) => ({
      ...i,
      sort_order: subIndex + 1
    }));
    setFieldValue(idBaseForDelete, sortedTempVal);
  };
  const columns = getSpecialIngredientCols();
  const [specialIngredientExpanded, setSpecialIngredientExpanded] = useState<any>();

  const getSpecialIngredientsItemHandler = async () => {
    setViewSpecialIngredientPopup(true);
    const res = await getSpecialIngredientsItem(localIngredientName?.id);
    let array: any = [];
    res?.ingredient_mappings?.forEach((data: any) => {
      if (data?.subIngredients) {
        array.push(false);
      }
    });
    setSpecialIngredientExpanded([...array]);
    setSpecialIngredientsItem(res);
  };

  const handleShowHide = (index: any) => {
    setSpecialIngredientExpanded((prevState: any) => {
      const tempState = [...prevState];
      tempState[index] = !tempState[index];
      return tempState;
    });
  };

  const setSpecialIngredientMeasurement = (ingredient_id: any) => {
    const currentIngredient = filteredSpecialIngredientsList?.filter(
      (x: any) => x?.id === ingredient_id
    );
    setLocalMeasurement(currentIngredient[0]?.measurement_id);
    setTimeout(() => {
      setFieldValue(`${idBase}.measurement_id`, currentIngredient[0]?.measurement_id);
    }, 200);
  };

  const getTableData = (curData: any) => {
    let tableData: any = [];
    let specialIngredientArray: any = [];
    let ingredientArray: any = [];
    curData?.forEach((data: any) => {
      if (data?.subIngredients) {
        specialIngredientArray.push(data);
      } else {
        if (data) {
          ingredientArray.push(data);
        }
      }
    });

    specialIngredientArray?.forEach((i: any, index: any) => {
      tableData.push({
        isSpecialIngredient: true,
        product_name: (
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleShowHide(index)}
          >
            {specialIngredientExpanded[index] ? (
              <DownOutlined style={{ marginRight: '5px', transform: 'rotate(180deg)' }} />
            ) : (
              <DownOutlined style={{ marginRight: '5px', transform: 'rotate(270deg)' }} />
            )}

            <TextBox style={{ width: '100%' }}>
              {i?.master_ingredient?.name ? i?.master_ingredient?.name : '--'}
            </TextBox>
          </div>
        ),
        amount: <TextBox>{i?.amount ? i?.amount : '--'}</TextBox>,
        mesurement: (
          <TextBox>
            {i?.special_ingredient_measurement_detail.name
              ? i?.special_ingredient_measurement_detail.name
              : '--'}
          </TextBox>
        ),
        prep_list: <TextBox>{i?.prep_detail?.name ? i?.prep_detail?.name : '--'}</TextBox>
      });
      if (specialIngredientExpanded[index]) {
        i?.subIngredients?.ingredient_mappings?.forEach((sub: any) => {
          tableData.push({
            product_name: (
              <TextBox style={{ marginLeft: '20px' }}>
                {sub?.master_ingredient?.name ? sub?.master_ingredient?.name : '--'}
              </TextBox>
            ),
            amount: <TextBox>{sub?.amount ? sub?.amount : '--'}</TextBox>,
            mesurement: (
              <TextBox>
                {sub?.special_ingredient_measurement_detail.name
                  ? sub?.special_ingredient_measurement_detail.name
                  : '--'}
              </TextBox>
            ),
            prep_list: <TextBox>{sub?.prep_detail?.name ? sub?.prep_detail?.name : '--'}</TextBox>
          });
        });
      }
    });
    ingredientArray?.forEach((pro: any) => {
      tableData.push({
        product_name: (
          <TextBox style={{ marginLeft: '20px' }}>
            {pro?.master_ingredient?.name ? pro?.master_ingredient?.name : '--'}{' '}
          </TextBox>
        ),
        amount: <TextBox>{pro?.amount ? pro?.amount : '--'}</TextBox>,
        mesurement: (
          <TextBox>
            {pro?.special_ingredient_measurement_detail.name
              ? pro?.special_ingredient_measurement_detail.name
              : '--'}
          </TextBox>
        ),
        prep_list: <TextBox>{pro?.prep_detail?.name ? pro?.prep_detail?.name : '--'}</TextBox>
      });
    });

    return tableData;
  };

  const ingredientNameSearchHandler = (value: string) => {
    valVarBase?.is_special_ingredient
      ? setFilteredSpecialIngredientsList(
          initialSpecialIngredientsList?.filter((item: any) =>
            item?.name?.toLowerCase()?.startsWith(value?.toLowerCase())
          )
        )
      : setFilteredIngredientsList(
          initialIngredientsList?.filter((item: any) =>
            item?.name?.toLowerCase()?.startsWith(value?.toLowerCase())
          )
        );
    setFieldValue(`${idBase}.ingredient_id`, '');
    setFieldValue(`${idBase}.ingredient_name`, value);
  };

  const ingredientChangeHandler = (value: any) => {
    setProductSubmit && setProductSubmit(false);
    setLocalIngredientName(value);
    if (valVarBase?.is_special_ingredient) setSpecialIngredientMeasurement(value?.id);
    if (value?.id > -1) {
      setTimeout(() => {
        setFieldValue(`${idBase}.ingredient_id`, value?.id);
        setFieldValue(`${idBase}.ingredient_name`, value?.name);
      }, 200);
    } else {
      setTimeout(() => {
        setFieldValue(`${idBase}.ingredient_id`, '');
        setFieldValue(`${idBase}.ingredient_name`, value?.name);
      }, 200);
    }
  };

  return (
    <>
      <Row gutter={[10, 10]} key={`ingredients-${index}-${valVarBase?.ingredient_id}`}>
        <Col span={5}>
          <SearchableSelect
            id={`${idBase}.ingredient_id`}
            name={`${idBase}.ingredient_id`}
            className="bottom-0"
            value={localIngredientName}
            placeholdertitle="Ingredient Name"
            keyValue={'id'}
            labelValue={'name'}
            error={
              (touchedVarBase?.ingredient_id && errorVarBase?.ingredient_id) ||
              errorVarBase?.ingredient_name
            }
            onSearch={ingredientNameSearchHandler}
            options={
              valVarBase?.is_special_ingredient
                ? filteredSpecialIngredientsList?.map((item: any) => ({
                    name: item?.name,
                    id: item?.id
                  }))
                : filteredIngredientsList?.map((item: any) => ({
                    name: item?.name,
                    id: item?.id
                  }))
            }
            onChange={ingredientChangeHandler}
            listHeight={150}
          />
        </Col>
        <Col span={3}>
          <Input
            id={`${idBase}.amount`}
            name={`${idBase}.amount`}
            value={localAmount}
            placeholder="0.00"
            className="bottom-0"
            onChange={(e: any) => {
              setProductSubmit && setProductSubmit(false);
              const valueToUpdate = e?.target?.value?.replace(/[^\d.-]/g, '', '');
              const result = valid(valueToUpdate) ? valueToUpdate : localAmount;
              setLocalAmount(result);
              setTimeout(() => {
                setFieldValue(`${idBase}.amount`, result);
              }, 200);
            }}
            onBlur={handleBlur}
          />
        </Col>
        <Col span={4}>
          <SelectInput
            id={`${idBase}.measurement_id`}
            name={`${idBase}.measurement_id`}
            value={localMeasurement}
            className="bottom-0"
            placeholdertitle="Unit"
            disabled={valVarBase?.is_special_ingredient}
            error={touchedVarBase?.measurement_id && errorVarBase?.measurement_id}
            onChange={(value: any) => {
              setProductSubmit && setProductSubmit(false);
              setLocalMeasurement((prev: string | number) => {
                if (prev !== value) {
                  setTimeout(() => {
                    setFieldValue(`${idBase}.measurement_id`, value);
                  }, 200);
                }
                return value;
              });
            }}
            listHeight={150}
          >
            {measurementsData?.map((item: any) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
        <Col span={4}>
          <SelectInput
            id={`${idBase}.prep_list_id`}
            name={`${idBase}.prep_list_id`}
            className="bottom-0"
            value={localPrepList}
            placeholdertitle="Select Prep List"
            error={touchedVarBase?.prep_list_id && errorVarBase?.prep_list_id}
            onChange={(value: any) => {
              setProductSubmit && setProductSubmit(false);
              setLocalPrepList((prev: string | number) => {
                if (prev !== value) {
                  setTimeout(() => {
                    setFieldValue(`${idBase}.prep_list_id`, value);
                  }, 200);
                }
                return value;
              });
            }}
            listHeight={150}
          >
            {prepListData?.map((item: any) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
        <Col span={4}>
          <SelectInput
            id={`${idBase}.visibility`}
            name={`${idBase}.visibility`}
            value={localVisibility}
            className="bottom-0"
            placeholdertitle="Select Visibility"
            error={touchedVarBase?.visibility && errorVarBase?.visibility}
            onChange={(value: any) => {
              setProductSubmit && setProductSubmit(false);
              setLocalVisibility((prev: string | number) => {
                if (prev !== value) {
                  setTimeout(() => {
                    setFieldValue(`${idBase}.visibility`, value);
                  }, 200);
                }
                return value;
              });
            }}
            listHeight={150}
          >
            {VisibilityOptions?.map((item: any) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>

        <Col span={4} style={{ display: 'flex', gap: '10px' }}>
          <div style={{ padding: '8px 5px', textAlign: 'left' }}>
            {!!valVarBase?.is_special_ingredient && (
              <>
                {localIngredientName?.id ? (
                  <H4Typography
                    style={{ color: '#FD7E14', cursor: 'pointer' }}
                    onClick={getSpecialIngredientsItemHandler}
                  >
                    View
                  </H4Typography>
                ) : (
                  <H4Typography style={{ color: 'gray' }}>View</H4Typography>
                )}
              </>
            )}
          </div>
          <Button
            variant="ghost"
            icon={<MinusCircleOutlined />}
            onClick={deleteIngredientItemHandler}
          />
        </Col>
      </Row>

      <ModalComponent
        visible={ViewSpecialIngredientPopup}
        onCancel={() => {
          setSpecialIngredientsItem([]);
          setViewSpecialIngredientPopup(false);
        }}
        modalWidth="798px !important"
        closable={true}
      >
        {specialIngredientsItem && Object.keys(specialIngredientsItem).length !== 0 ? (
          <>
            <h2
              style={{ fontWeight: '600', textAlign: 'start', marginBottom: '40px' }}
              onClick={getSpecialIngredientsItemHandler}
            >
              VIEW SPECIAL INGREDIENT
            </h2>
            <SpecialIngredientWraper>
              <DataTable
                style={{ border: '1px solid #CDCDCD', borderRadius: '4px' }}
                columns={columns}
                dataSource={getTableData(specialIngredientsItem?.ingredient_mappings)}
                pagination={false}
                renderEmpty={() => {
                  return <p>No Records Found</p>;
                }}
                className={'specialIngredientsTable'}
                onRowClick={() => {}}
              />
            </SpecialIngredientWraper>
          </>
        ) : (
          <Spin />
        )}
      </ModalComponent>
    </>
  );
};

export default IngredientRowItem;
