import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import { Flex } from '@atom/Flex';
import FormDataView from '@atom/FormDataView';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import ConfirmModal from '@organism/ConfimationModal';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { getSettings, saveSettings } from './helper';

interface SettingsListType {
  key: string;
  value: string;
}

const HstFees = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [hstFees, setHstFees] = useState('');
  const [hstError, setHstError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getSettingshandler();
  }, []);

  const getSettingshandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getSettings();
    setPageLoading(false);
    if (!result.error) {
      const hstValue = (result.data || []).find(
        (item: SettingsListType) => item.key === 'hst_fee'
      )?.value;
      setHstFees(hstValue || '');
    }
  }, []);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setHstFees(e.target.value);
    setHstError(e.target.value?.length === 0);
  };

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    setHstError(false);
    getSettingshandler();
    setShowSaveConfirmModal(false);
  };

  const onSubmit = async () => {
    if (hstError) return;
    setLoading(true);
    const result = await saveSettings(hstFees);
    setShowSaveConfirmModal(false);
    setLoading(false);
    if (result.data) {
      showToast({
        message: `Settings Updated Successfully!`,
        description: ''
      });
      onCancelHandler();
    } else {
      showErrorToast({
        message: `Unable to update settings`,
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Label text={`HST Fees`} isMandatory={false} />
            <Input
              id="hst_fees"
              name="hst_fees"
              value={hstFees}
              placeholder={`HST Fees`}
              onChange={onChangeHandler}
              error={hstError ? 'HST Fees is required' : ''}
            />
          </Col>
        </Row>
        <Flex gap={8} justifyContent={'flex-end'} className="fix-mobile">
          <Button text="Cancel" variant="secondary" onClick={onCancelHandler} isDisable={loading} />
          <Button
            text="Submit"
            variant="primary"
            onClick={() => !hstError && setShowSaveConfirmModal(true)}
            isLoading={loading}
          />
        </Flex>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={() => setShowSaveConfirmModal(false)}
          title="Saving will impact existing entries."
          onOK={onSubmit}
          isModalLoading={loading}
          okText="Okay"
        >
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <Flex style={{ flexFlow: 'wrap', gap: '40px' }}>
      <FormDataView heading="HST Fees" value={`${hstFees}%`} />
    </Flex>
  );
};

export default HstFees;
