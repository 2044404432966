import Email from '@pages/GlobalSettingsPage/Settings/Email';
import HstFees from '@pages/GlobalSettingsPage/Settings/HstFees';
import services from '@services/index';
import { ResponseType } from '../helper';
import * as yup from 'yup';

export const emailValidationSchema = yup.object({
  emails: yup.array().of(
    yup.object({
      email: yup.string().required('Email is required.').nullable()
    })
  )
});

// api calls
export const getSettings = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const saveSettings = async (value: string): Promise<ResponseType> => {
  try {
    const body = {
      settings: [
        {
          key: 'hst_fee',
          value: value
        }
      ]
    };
    const res = (await services.post(`/settings`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const enum SETTING_TAB {
  HST_FEES = 'hst-fees',
  FEEDBACK = 'Feedback'
}

export const settingsGlobalTabValue = () => {
  return [
    { tab: 'HST Fees', content: <HstFees />, key: SETTING_TAB.HST_FEES },
    { tab: 'Feedback', content: <Email />, key: SETTING_TAB.FEEDBACK }
  ];
};

export const getEmailList = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings/emails`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateUserEmail = async (body: EditEmailTypes): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/settings/emails`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};
