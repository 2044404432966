import { useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import PlusCircle from '@assets/plus-circle.svg';
import RemoveIcon from '@assets/removerow.png';
import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import { Input } from '@atom/Input';
import { H4Typography } from '@constant/typography/Typography';
import ConfirmModal from '@organism/ConfimationModal';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Col, Row } from 'antd';
import { FieldArray, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { emailValidationSchema, getEmailList, updateUserEmail } from './helper';
import { UserEmailWrapper } from './style';

export interface SubmitValueType {
  index: number;
  arrayHelper: any;
  id?: number | null;
}

const Email = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailList, setEmailList] = useState<EmailListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [showSaveChangeConfirmModal, setShowSaveChangeConfirmModal] = useState(false);

  const [submitValue, setSubmitValue] = useState<SubmitValueType | null>(null);
  const [formValues, setFormValues] = useState<EmailListType[]>([]);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getEmailHandler();
  }, []);

  const getEmailHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getEmailList();
    setPageLoading(false);
    if (!result.error) {
      setEmailList(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getEmailHandler();
    onModalCancelHandler();
  };

  const onSubmitHandler = (values: SubmitValueType) => {
    setSubmitValue(values);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const onEmailDelete = async () => {
    if (submitValue?.id) {
      showToast({ message: 'Email Removed Successfully', description: '' });
    }
    onModalCancelHandler();
    submitValue?.arrayHelper && submitValue.arrayHelper.remove(submitValue.index);
  };

  const updateEmailHandler = (values: EditEmailTypes) => {
    setShowSaveChangeConfirmModal(true);
    setFormValues(values.emails);
  };
  const onSubmit = async () => {
    setLoading(true);
    const result = await updateUserEmail({ emails: formValues });
    setShowSaveChangeConfirmModal(false);
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'User Email Updated Successfully', description: '' });
      onCancelHandler();
    } else {
      showErrorToast({
        message: 'Error Saving User Email',
        description: result.error || 'Please try again'
      });
    }
  };
  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H4Typography>User Email</H4Typography>
        </Flex>
        <Formik
          initialValues={{ emails: emailList }}
          validationSchema={emailValidationSchema}
          onSubmit={updateEmailHandler}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, handleBlur, touched, errors }) => {
            return (
              <Flex direction="column">
                <FieldArray
                  name={'emails'}
                  render={(arrayHelper: any) => {
                    return values?.emails?.map((item, index) => (
                      <Row key={index} gutter={[16, 16]}>
                        <Col md={11} xs={20}>
                          <Input
                            id={`emails[${index}].email`}
                            name={`emails[${index}].email`}
                            value={item.email}
                            placeholder={`User Email`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched?.emails?.[index]?.email &&
                              (errors?.emails as { email: string }[])?.[index]?.email
                            }
                          />
                        </Col>
                        <Col
                          md={1}
                          xs={4}
                          className="centerPosition"
                          style={{ opacity: 1, textAlign: 'center' }}
                        >
                          <img
                            style={{ paddingTop: '10px' }}
                            src={RemoveIcon}
                            alt="remove"
                            onClick={() => onSubmitHandler({ index, arrayHelper, id: item?.id })}
                          />
                        </Col>
                      </Row>
                    ));
                  }}
                />
                {values?.emails?.length < 5 && (
                  <Flex bottom={24}>
                    <Button
                      text="Add User Email"
                      variant="secondary"
                      icon={<img width={20} src={PlusCircle} alt="add" />}
                      onClick={() =>
                        setFieldValue('emails', [...values.emails, { id: '', email: null }])
                      }
                    />
                  </Flex>
                )}
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8} className="fix-mobile">
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Are you sure you want to delete the User Email?"
          onOK={onEmailDelete}
          isModalLoading={false}
          okText="Confirm Delete"
        />
        <ConfirmModal
          visible={showSaveChangeConfirmModal}
          onCancel={() => setShowSaveChangeConfirmModal(false)}
          title="Saving will impact existing entries."
          onOK={onSubmit}
          isModalLoading={loading}
          okText="Okay"
        >
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <UserEmailWrapper>
      <Flex bottom={26}>
        <H4Typography>User Email</H4Typography>
      </Flex>
      <Flex direction="column">
        {emailList.map((item, index) => (
          <p
            className="email-list"
            key={item.id}
            style={{
              background: index % 2 === 0 ? '#f8fafb' : ''
            }}
          >
            {item.email}
          </p>
        ))}
      </Flex>
    </UserEmailWrapper>
  );
};

export default Email;