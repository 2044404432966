import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import Checkbox from '@atom/Checkbox';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import ImagePreviewer from '@atom/ImagePreviewer';
import { Input } from '@atom/Input';
import { FILE_TYPES } from '@constants/userConstants';
import { FileDraggerComponent } from '@molecules/FileDraggerComponent';
import ConfirmModal from '@organism/ConfimationModal';
import { uploadFileHandler } from '@pages/EmployeeManagementPage/AddEditEmployee/helper';
import { INGREDIENT_FORM_STATUS } from '@pages/MenuManagementPage/helper';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Col, Row } from 'antd';
import { getIn, useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import IngredientsVirtualList from './IngredientVirtualList';
import {
  getIngredients,
  getIngredientsData,
  getMappedValues,
  IIngredientType,
  IngredientsListType,
  InitialValues,
  saveIngredientsData,
  SpecialIngredientsListType,
  validationSchema
} from './helper';
import { IngredientButtonWrapper, IngredientsWrapper } from './style';

interface IngredientsSectionProps {
  currentSubCategory: number | null;
  subCategoryId: number;
  isClearWarningModalVisible: boolean;
  setIsClearWarningModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  measurementsData: any[];
  prepListData: any[];
  primarySubcategoryIngredients?: {
    isTherePrimarySubcategoryIngredients: boolean;
    primarySubcategoryIngredientsData: any;
  };
  setProductSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
  handleParentErrors: () => boolean;
  forQuotePopUp: boolean;
  formik: any;
  productListFromQuote: any;
  setPrimarySubcategoryData: (id: string, subCategoryId: number) => Promise<void>;
}

const IngredientsSection = forwardRef<any, IngredientsSectionProps>((props, ref) => {
  const {
    forQuotePopUp,
    currentSubCategory = 0,
    subCategoryId,
    isClearWarningModalVisible = false,
    setIsClearWarningModalVisible,
    measurementsData = [],
    prepListData = [],
    primarySubcategoryIngredients = {
      isTherePrimarySubcategoryIngredients: true,
      primarySubcategoryIngredientsData: {}
    },
    setProductSubmit,
    handleParentErrors,
    formik,
    setPrimarySubcategoryData,
    productListFromQuote
  } = props;

  const parentProductValues = useSelector((state: any) => state.rawData?.parentProductValues);
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const [ingredientsData, setIngredientsData] = useState(InitialValues());
  const [isLoading, setIsLoading] = useState({
    ingredientsDataLoading: false,
    listLoading: false
  });

  const [ingredientsList, setIngredientsList] = useState<IngredientsListType[]>([]);
  const [specialIngredientsList, setSpecialIngredientsList] = useState<
    SpecialIngredientsListType[]
  >([]);
  const [imageLoading, setImageLoading] = useState(false);
  const { ingredientsFormState } = useSelector((state: any) => state?.rawData);
  const { values: globalValues } = formik;

  useEffect(() => {
    if (subCategoryId && parentProductValues) getIngredientsDataMethod(subCategoryId);
  }, [subCategoryId, parentProductValues]);

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit
    }),
    []
  );

  useEffect(() => {
    if (!!parentProductValues && Object.keys(parentProductValues)?.length > 0){
      setIngredientsData(
        getMappedValues(values, {}, parentProductValues, subCategoryId, forQuotePopUp)
      );
    }
  }, [parentProductValues]);

  const fetchIngredientsList = async () => {
    const res = await getIngredients(false);
    setIngredientsList(res);
  };

  const fetchSpecialIngredientsList = async () => {
    const res = await getIngredients(true);
    setSpecialIngredientsList(res);
  };

  const fetchLists = async () => {
    setIsLoading((prev) => ({ ...prev, listLoading: true }));
    await fetchIngredientsList();
    await fetchSpecialIngredientsList();
    setIsLoading((prev) => ({ ...prev, listLoading: false }));
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const submitHandler = async (values: any, actions: any) => {
    if (handleParentErrors()) return;
    setIsLoading((prev) => ({ ...prev, ingredientsDataLoading: true }));
    const newingredients = values?.product_subcategory?.ingredients?.map(
      (ingredientItem: IIngredientType) => {
        return {
          ...ingredientItem,
          visibility: ingredientItem?.visibility ?? '',
          ingredient_id: ingredientItem?.ingredient_id ? ingredientItem?.ingredient_id : undefined,
          ingredient_name: !ingredientItem?.ingredient_id
            ? ingredientItem?.ingredient_name
            : undefined,
          amount: ingredientItem?.amount ?? '',
          measurement_id: ingredientItem?.measurement_id ?? '',
          prep_list_id: ingredientItem?.prep_list_id ?? '',
          is_new: !ingredientItem?.ingredient_id ? 1 : 0
        };
      }
    );

    try {
      let { values: globalFormikValue, setValues: setGlobalValues } = formik;
      const dataToSend = {
        ...values,
        product_id: id ? id : ''
      };
      if (forQuotePopUp) {
        if (
          values?.is_one_time_product &&
          productListFromQuote?.find((item: any) => item?.product_id == dataToSend?.product_id)
        ) {
          dataToSend.product_id = '';
        }
      }
      const res: any = await saveIngredientsData({
        ...dataToSend,
        product_subcategory: {
          ...values?.product_subcategory,
          description: values?.product_subcategory?.description || '',
          image: values?.product_subcategory?.image || '',
          is_primary: values?.product_subcategory?.is_primary ?? false,
          ingredients: newingredients?.map((item: any) => ({ ...item })) || []
        }
      });
      if (res?.success) {
        showToast({
          message: 'Saved!',
          description: 'Product subcategory details saved successfully'
        });
        await fetchIngredientsList();
        setProductSubmit && setProductSubmit(true);
        if (values?.product_subcategory?.is_primary) {
          globalFormikValue.sub_category_array.forEach((x: any, i: number) => {
            i !== currentSubCategory ? (x.is_primary = false) : (x.is_primary = true);
          });
        } else {
          globalFormikValue.sub_category_array.forEach((x: any, i: number) => {
            i === currentSubCategory && (x.is_primary = false);
          });
        }
        setGlobalValues({ ...globalFormikValue, id: res?.data?.product_id });
        setPrimarySubcategoryData(id as string, subCategoryId);
        // ingredientsFormik?.resetForm({ values }); // to reset dirty to true
        updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
        if (!id && !forQuotePopUp) {
          navigate(`/menu/active/edit-product/${res?.data?.product_id}`);
        }
      } else {
        setErrors({ ...errors, ...res?.error?.error?.error });
        const resErrors = res?.error?.error?.error;
        if (resErrors) {
          for (let field in resErrors) {
            let fieldError = '';
            if (
              resErrors[field]?.[0]?.includes('ingredient_name') &&
              resErrors[field]?.[0]?.includes('has already been taken.')
            ) {
              fieldError = 'The ingredient already exists,It needs to be selected from list';
            }
            setFieldError(field, fieldError || resErrors[field]);
          }
        }
        showErrorToast({
          message: 'Failed!',
          description:
            'Failed to save product details! \n Please make sure all details are correctly filled'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to save product details.'
      });
    }
    setIsLoading((prev) => ({ ...prev, ingredientsDataLoading: false }));
  };

  const ingredientsFormik = useFormik({
    initialValues: ingredientsData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: submitHandler
  });

  const getIngredientsDataMethod = async (subCategoryId: number) => {
    setIsLoading((prev) => ({ ...prev, ingredientsDataLoading: true }));
    ingredientsFormik?.resetForm();
    const productId = id ?? globalValues?.id;
    if (productId) {
      const res = await getIngredientsData(productId as string, subCategoryId);
      const oldData = res?.data ? values : InitialValues();
      setIngredientsData(
        getMappedValues(res?.data, oldData, parentProductValues, subCategoryId, forQuotePopUp)
      );
    } else {
      setIngredientsData(
        getMappedValues({}, values, parentProductValues, subCategoryId, forQuotePopUp)
      );
    }
    setIsLoading((prev) => ({ ...prev, ingredientsDataLoading: false }));
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    dirty,
    setValues,
    handleBlur,
    setErrors,
    setFieldError
  } = ingredientsFormik;

  useEffect(() => {
    if (!dirty || ingredientsFormState === INGREDIENT_FORM_STATUS.MODIFIED) return;
    if (dirty) updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.MODIFIED });
  }, [dirty]);

  const touchedValue = (key: string) => getIn(touched, key);
  const errorValue = (key: string) => getIn(errors, key);

  const onImageRemove = () => {
    setProductSubmit && setProductSubmit(false);
    setFieldValue && setFieldValue('product_subcategory.image', '');
    setFieldValue && setFieldValue('product_subcategory.imageName', '');
  };

  const handleFileUpload = async (file: File) => {
    setProductSubmit && setProductSubmit(false);
    setImageLoading(true);
    const result = await uploadFileHandler(file, FILE_TYPES.PROFILE_PIC);
    setImageLoading(false);
    if (result.error) {
      return showErrorToast({
        message: `Unable to upload profile picture`,
        description: result.error || 'Please try again'
      });
    }
    setFieldValue && setFieldValue('product_subcategory.image', result.data.url);
    setFieldValue && setFieldValue('product_subcategory.imageName', file?.name);
  };

  const onModalCancelHandler = () => {
    setIsClearWarningModalVisible(false);
  };

  const clearProgress = () => {
    setIsLoading((prev) => ({ ...prev, ingredientsDataLoading: true }));
    const initialProdutSubcategory = InitialValues()?.product_subcategory;
    initialProdutSubcategory.subcategory_id = values?.product_subcategory?.subcategory_id;
    ingredientsFormik?.setFieldValue('product_subcategory', initialProdutSubcategory);
    setIsClearWarningModalVisible(false);
    setTimeout(() => setIsLoading((prev) => ({ ...prev, ingredientsDataLoading: false })), 300);
  };

  const copyIngredientHandler = () => {
    const tempCopyIngredients = [
      ...primarySubcategoryIngredients?.primarySubcategoryIngredientsData?.product_subcategory
        .ingredients
    ];
    tempCopyIngredients.forEach((x) => {
      delete x['id'];
    });
    const tempData = { ...values };
    tempData.product_subcategory.ingredients = tempCopyIngredients;
    setIngredientsData(tempData);
    setValues(tempData);
  };

  const handleCopyPasteIngredient = () => {
    setIsLoading((prev) => ({ ...prev, listLoading: true }));

    const copyPasteIngredientInput = values?.product_subcategory?.copy_paste_ingredient || '';
    const existingIngredients = values.product_subcategory.ingredients || [];

    const existingIngredientNames = new Set(
      existingIngredients.map((ingredient) => ingredient?.ingredient_name).filter(Boolean)
    );

    const inputIngredients = copyPasteIngredientInput
      .split(',')
      .map((value) => value.trim())
      .filter(Boolean);

    const newInputIngredients = inputIngredients.filter(
      (name) => !existingIngredientNames.has(name)
    );

    const newIngredients = newInputIngredients.map((name) => {
      const matchedIngredient = ingredientsList.find((item) => item.name === name);

      if (matchedIngredient) {
        return {
          is_special_ingredient: 0,
          ingredient_name: matchedIngredient.name,
          ingredient_id: matchedIngredient.id
        };
      }

      return {
        is_special_ingredient: 0,
        ingredient_name: name,
        ingredient_id: ''
      };
    });

    const combinedIngredientList = [...existingIngredients, ...newIngredients];

    const finalIngredientList = combinedIngredientList.map((ingredient, index) => ({
      ...ingredient,
      sort_order: index + 1
    }));

    setFieldValue('product_subcategory.copy_paste_ingredient', '');
    setFieldValue('product_subcategory.ingredients', finalIngredientList);
    setIsLoading((prev) => ({ ...prev, listLoading: false }));
  };

  const copyPasteIngredientError =
    touchedValue('product_subcategory.copy_paste_ingredient') &&
    errorValue('product_subcategory.copy_paste_ingredient');

  if (isLoading?.ingredientsDataLoading) return <CenterSpiner />;

  return (
    <>
      <IngredientsWrapper>
        <Row style={{ width: '100%' }}>
          <Col md={24} xs={24}>
            <Row style={{ backgroundColor: '#F4F4F4', borderRadius: '0px 5px 0px 0px' }}>
              <Col
                xs={24}
                lg={15}
                md={24}
                style={{ display: 'inline-flex', padding: '12px 12px 12px 12px' }}
              >
                <Label text="Ingredients" isMandatory />
                <span
                  style={{
                    color: '#A19F9F',
                    padding: '4px 0px 0px 5px',
                    fontSize: '14px',
                    lineHeight: '18px'
                  }}
                >
                  Define all ingredients used in this product
                </span>
              </Col>
              <Col
                xs={24}
                lg={9}
                md={24}
                style={{
                  display: '-webkit-inline-box',
                  alignItems: 'end',
                  padding: '10px 12px 10px 12px'
                }}
              >
                {!!subCategoryId && (
                  <Flex
                    className="ingredient-btn"
                    style={{
                      marginLeft: 'auto',
                      justifyContent: 'center',
                      width: '100%',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      text="Clear"
                      variant="secondary"
                      onClick={() => setIsClearWarningModalVisible(true)}
                      style={{ minWidth: '40%' }}
                    />
                    <Button
                      style={{ marginLeft: '20px', minWidth: '40%' }}
                      text="Save"
                      variant="secondary"
                      onClick={handleSubmit}
                    />
                  </Flex>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                {!!subCategoryId && (
                  <>
                    <Flex style={{ width: '100%', padding: '12px' }} direction="column">
                      <Flex>
                        <h3 style={{ fontSize: '14px', fontWeight: '600' }}>Price & Description</h3>
                      </Flex>
                      <Flex>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col md={6} xs={24}>
                            <Label text="Price" isMandatory />
                            <Input
                              name="product_subcategory.price"
                              id="price"
                              placeholder="$0.00"
                              value={values?.product_subcategory?.price}
                              onChange={(e) => {
                                handleChange(e);
                                setProductSubmit && setProductSubmit(false);
                              }}
                              onBlur={handleBlur}
                              error={
                                touchedValue('product_subcategory.price') &&
                                errorValue('product_subcategory.price')
                              }
                            />
                          </Col>
                          <Col md={18} xs={24}>
                            <Label text="Description" />
                            <Input
                              type="text"
                              name="product_subcategory.description"
                              id="description"
                              placeholder="Enter Description"
                              value={values?.product_subcategory?.description}
                              onChange={(e) => {
                                handleChange(e);
                                setProductSubmit && setProductSubmit(false);
                              }}
                              onBlur={handleBlur}
                              error={
                                touchedValue('product_subcategory.description') &&
                                errorValue('product_subcategory.description')
                              }
                            />
                          </Col>
                        </Row>
                      </Flex>

                      <Flex>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col md={24} xs={24}>
                            <Label text="Image" />
                            <Row style={{ width: '100%' }}>
                              {values?.product_subcategory?.image ? (
                                <ImagePreviewer
                                  image={values?.product_subcategory?.image || ''}
                                  closable
                                  onClose={onImageRemove}
                                />
                              ) : (
                                <FileDraggerComponent
                                  loading={imageLoading}
                                  onChangeHandle={handleFileUpload}
                                  format="pdf"
                                />
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Flex>

                      <Flex direction="column" style={{ marginBottom: 16, marginTop: 30 }}>
                        <IngredientButtonWrapper>
                          <Flex gap={16} direction="column">
                            <Flex gap={16} justifyContent={'space-between'}>
                              <Input
                                type="text"
                                id={`copy_paste_ingredient`}
                                name={`product_subcategory.copy_paste_ingredient`}
                                placeholder={'Copy and paste the ingredients into the table.'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={copyPasteIngredientError}
                                value={values?.product_subcategory?.copy_paste_ingredient}
                                styleForWrapper={{ marginBottom: '0px' }}
                              />
                              <Button
                                text={'Copy'}
                                isDisable={
                                  !(
                                    values?.product_subcategory?.copy_paste_ingredient &&
                                    values?.product_subcategory?.copy_paste_ingredient?.length > 1
                                  ) || copyPasteIngredientError
                                }
                                style={{ height: '48px' }}
                                onClick={handleCopyPasteIngredient}
                                variant={'primary'}
                              />
                            </Flex>
                            <Flex justifyContent={'space-between'}>
                              <Flex style={{ flex: 1, alignItems: 'baseline' }} gap={12}>
                                <Label text="Ingredients" />
                                {primarySubcategoryIngredients?.isTherePrimarySubcategoryIngredients &&
                                  !values?.product_subcategory?.is_primary && (
                                    <Button
                                      className="ingredients-btn"
                                      text="Copy Ingredients from Primary Sub Category"
                                      variant="secondary"
                                      onClick={copyIngredientHandler}
                                    />
                                  )}
                              </Flex>
                              <Flex style={{ flex: 1 }} justifyContent="end">
                                <Checkbox
                                  name="Mark as Primary Sub Category"
                                  id="product_subcategory.is_primary"
                                  onChange={(e: React.ChangeEvent<any>) => {
                                    if (!setFieldValue) return;
                                    setProductSubmit && setProductSubmit(false);
                                    setFieldValue(
                                      'product_subcategory.is_primary',
                                      e?.target?.checked
                                    );
                                  }}
                                  checked={values?.product_subcategory?.is_primary}
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </IngredientButtonWrapper>
                      </Flex>

                      <Flex direction="column">
                        <IngredientsVirtualList
                          formik={ingredientsFormik}
                          measurementsData={measurementsData}
                          prepListData={prepListData}
                          setProductSubmit={setProductSubmit}
                          specialIngredientsList={specialIngredientsList}
                          ingredientsList={ingredientsList}
                          isLoading={isLoading?.listLoading}
                        />
                        {/* Added to show Reference Ingredients from old system */}
                        {!globalValues?.is_subcategory_ingredient_exist && (
                          <Flex
                            style={{ flex: 1, alignItems: 'baseline', paddingTop: '10px' }}
                            gap={12}
                          >
                            <Label
                              style={{ color: 'red' }}
                              text="Reference Ingredients from old system:"
                            />
                            {globalValues?.product_ingredient_list
                              ?.map(
                                (ingredientItem: any) => ingredientItem?.id && ingredientItem?.name
                              )
                              .filter(Boolean)
                              .join(', ')}
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {!!isClearWarningModalVisible && (
          <div>
            <ConfirmModal
              visible={isClearWarningModalVisible}
              onCancel={onModalCancelHandler}
              title="You are about to clear your progress."
              onOK={clearProgress}
              okText="Clear"
            >
              <span>
                Are you sure you want to clear all information and ingredients of the sub category?
                This action cannot be undone.
              </span>
            </ConfirmModal>
          </div>
        )}
      </IngredientsWrapper>
    </>
  );
});

export default IngredientsSection;
