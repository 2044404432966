import { Col, Row } from 'antd';
import { IAccountManager, IAddEditInfoProps } from '@pages/VenueManagementPage/types';
import { VenueSubSections, getAccountManager } from '../helper';
import { getAddressComps, getLocationByPlaceId } from '@utils/googlemaphealper';
import { useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';
import { GoogleAutoComplete } from '@atom/GoogleAutoComplete';
import { Input } from '@atom/Input';
import { Label } from '@atom/FormLable';
import SearchableSelect from '@atom/SearchableSelect';
import SelectInput from '@atom/Select';
import StatusLabel from '@atom/StatusLabel';
import TimePickerComponent from '@atom/TimePicker';
import { debounceCall } from '@utils/index';
import moment, { Moment } from 'moment';
import { useParams } from 'react-router';
import { getSelectOptions } from '@utils/helper';
import { getIn } from 'formik';

function VenueDetailsSection({ formik }: IAddEditInfoProps) {
  const { id } = useParams();
  const { values, handleBlur, setFieldValue, setTouched, touched, errors } = formik;
  const [venueDetails, setVenueDetails] = useState<any>({});
  const [accountManagerNameList, setAccountManagerNameList] = useState<IAccountManager[]>([]);

  useEffect(() => {
    if (!!values?.account_manager && Object.keys(values?.account_manager)?.length > 0) {
      const tempAccountManagerNameList = [...accountManagerNameList];
      const check = accountManagerNameList.find((item) => item.id === values?.account_manager?.id);
      !check && tempAccountManagerNameList?.push(values?.account_manager);
      !check && setAccountManagerNameList(tempAccountManagerNameList);
    }
  }, [values?.account_manager]);

  useEffect(() => {
    if (values.id) {
      setVenueDetails({ ...values });
    }
  }, [values.id]);

  const handleChange = (e: any) => {
    setTimeout(() => {
      setFieldValue(e?.target?.id, e?.target?.value);
    }, 200);
    setVenueDetails({ ...venueDetails, [e?.target?.id]: e?.target?.value });
  };

  const onPlaceSelect = async (placeId: string) => {
    if (placeId) {
      const place: any = await getLocationByPlaceId(placeId);
      if (place) {
        const locObj = getAddressComps(place);
        if (locObj.formattedAddress.length > 0) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };
          setFieldValue(
            'venue_address',
            `${locObj?.formattedAddress}${locObj?.neighbourhood && ', ' + locObj?.neighbourhood}${
              locObj?.city && ', ' + locObj?.city
            }${locObj?.state && ', ' + locObj?.state}${
              locObj?.country && ', ' + locObj?.country
            }` || ''
          );
          setFieldValue('latitude', coordinates?.lat || '');
          setFieldValue('longitude', coordinates?.lng || '');
        }
      }
    }
  };

  const searchClientHandler = async (searchValue: string) => {
    const nameList = await getAccountManager(searchValue);
    return nameList as any;
  };

  const nameSearchHandler = async (name: string) => {
    if (name.length) {
      const { data }: any = await debounceCall(searchClientHandler, name, 500);
      setAccountManagerNameList(data ?? []);
    }
  };

  return (
    <Flex direction="column" style={{ width: '100%' }}>
      <div id="map" />
      <Row className="custumerdetails-mobile-fix" gutter={16} justify="space-between">
        <Col xs={24} lg={12}>
          <Label isMandatory={true} text="Venue Name" />
          <Input
            name="venue_name"
            id="venue_name"
            error={touched?.venue_name && errors?.venue_name}
            placeholder="Type Name"
            onChange={handleChange}
            value={venueDetails?.venue_name}
            onBlur={handleBlur}
          />
        </Col>
        {id && (
          <Col className="status-divfixing" xs={24} lg={2} style={{ marginLeft: 'auto' }}>
            <Label style={{ marginBottom: '10px' }} text="STATUS" />
            {(!!values?.status || values?.status === 0) && (
              <StatusLabel isUppercase={true} status={values?.status} />
            )}
          </Col>
        )}
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text="Venue Subsection" />
          <SelectInput
            id={`venue_sub_section`}
            name={`venue_sub_section`}
            placeholdertitle="Select One"
            value={venueDetails?.venue_sub_section || null}
            onChange={(id: string) => {
              setVenueDetails({ ...venueDetails, venue_sub_section: id });
              setFieldValue(`venue_sub_section`, id);
            }}
            onBlur={handleBlur}>
            {getSelectOptions(VenueSubSections, 'value')}
          </SelectInput>
        </Col>
        <Col md={12} xs={24}>
          <Label isMandatory={true} text="Venue Address" />
          <GoogleAutoComplete
            apiKey={process.env.REACT_APP_GOOGLEAPI_KEY}
            id="venue_address"
            name="venue_address"
            value={venueDetails?.venue_address}
            placeholder="Type Address"
            onPlaceSelect={(place: any) => {
              if (place) onPlaceSelect(place.place_id);
            }}
            error={touched?.venue_address && errors?.venue_address}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text="Venue Capacity" />
          <Input
            name="capacity"
            id="capacity"
            error={touched?.capacity && errors?.capacity}
            placeholder="Type Capacity"
            onChange={handleChange}
            value={venueDetails?.capacity}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={12} xs={24}>
          <Label text="FD Account Manager" />
          <SearchableSelect
            id="fd_account_manager"
            name="fd_account_manager"
            options={accountManagerNameList}
            error={touched?.fd_account_manager && errors?.fd_account_manager}
            keyValue={'id'}
            labelValue={'name'}
            placeholdertitle={'Search For Account Manager'}
            onChange={(e: IAccountManager) => setFieldValue('fd_account_manager', e?.id)}
            onBlur={handleBlur}
            value={{
              name: accountManagerNameList?.find((item) => item.id === values?.fd_account_manager)
                ?.name
              // values?.account_manager?.name ?? '',
            }}
            onSearch={nameSearchHandler}
            allowClear={false}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text="Venue Availability" />
          <Row gutter={16}>
            <Col xs={12} md={12}>
              <TimePickerComponent
                id="start_time"
                name="start_time"
                use12Hours={true}
                format="h:mm A"
                placeholder={'HH:MM'}
                value={
                  venueDetails?.start_time &&
                  moment(venueDetails?.start_time, 'hh:mm A').format('HH:mm')
                }
                onChange={(value: string) => {
                  setTouched({ ...touched, start_time: true });
                  setFieldValue('start_time', value);
                  setVenueDetails({ ...venueDetails, start_time: value });
                }}
                onSelect={(e: Moment) => {
                  setFieldValue('start_time', e.format('hh:mm A'));
                  setVenueDetails({ ...venueDetails, start_time: e.format('hh:mm A') });
                }}
                error={touched?.start_time && errors?.start_time}
              />
            </Col>
            <Col xs={12} md={12}>
              <TimePickerComponent
                id="end_time"
                name="end_time"
                use12Hours={true}
                format="h:mm A"
                placeholder={'HH:MM'}
                value={
                  venueDetails?.end_time &&
                  moment(venueDetails?.end_time, 'hh:mm A').format('HH:mm')
                }
                onChange={(value: string) => {
                  setTouched({ ...touched, end_time: true });
                  setFieldValue('end_time', value);
                  setVenueDetails({ ...venueDetails, end_time: value });
                }}
                onSelect={(e: Moment) => {
                  setFieldValue('end_time', e.format('hh:mm A'));
                  setVenueDetails({ ...venueDetails, end_time: e.format('hh:mm A') });
                }}
                error={touched?.end_time && errors?.end_time}
              />
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={24}>
          <Label text="Staff Entrance And Parking" isMandatory={false} />
          <Input
            name="venue_detail.parking_additional_information"
            id="venue_detail.parking_additional_information"
            placeholder="Additional Info"
            onChange={(e) =>
              setFieldValue('venue_detail.parking_additional_information', e?.target?.value)
            }
            value={getIn(values, `venue_detail.parking_additional_information`) ?? ''}
            error={
              getIn(touched, `venue_detail.parking_additional_information`) &&
              getIn(errors, `venue_detail.parking_additional_information`)
            }
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </Flex>
  );
}

export default VenueDetailsSection;