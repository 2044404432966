import { QUOTE_PROPOSAL_IMAGES } from '@constants/index';
import services from '@services/index';
import * as Yup from 'yup';

export const uploadLogo = async (file: File): Promise<ResponseType> => {
  try {
    const formData = new FormData();
    formData.append('logo', file);
    const res = (await services.post(`/file-upload/save-logo`, formData, undefined, null, {
      'Content-Type': 'multipart/form-data'
    })) as any;
    //@ts-ignore
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    //@ts-ignore
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const dowloadEventPdf = async (data: any, event_id: any) => {
  try {
    const res = (await services.put(`/events/${event_id}/download-pdf`, data)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getContractDetails = async (event_id: any) => {
  try {
    const res = (await services.get(`/contract/${event_id}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const ViewContractDetails = async (event_id: string, envelope_id: string) => {
  try {
    const res = (await services.get(`/contract/${event_id}/${envelope_id}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const generateContract = async (data: ContractFormValues) => {
  try {
    const res = (await services.post(`/contract/generate-url`, data)) as {
      data: ContractFormValues;
    };
    return { data: res?.data };
  } catch (err: any) {
    return { error: err && err?.error ?  err?.error?.error || err?.error?.message : 'Something went wrong!' };
  }
};

export const sendContractCallback = async (data: any) => {
  try {
    const res = (await services.post(`/contract/callback`, data)) as {
      data: any;
    };
    return { data: res?.data };
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const dowloadEventContractPdf = async (params: ContractPDFDownloadPayloadTypes) => {
  try {
    const { data } = (await services.put(`/events/contract`, params)) as {
      data: { data: { url: string } };
    };

    return data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error : 'Something went wrong!' };
  }
};

export const getUniqueKey = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_+=@#$%&*';
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDownloadPdfData = (eventId: any, coverImage?: string, logoImage?: string) => {
  const data = {
    event_id: parseInt(eventId),
    pdf_images: [
      {
        id: 1,
        unique_key: getUniqueKey(),
        title: 'Cover Page',
        name: 'Cover Page',
        slug: 'cover_page',
        image: coverImage || QUOTE_PROPOSAL_IMAGES.coverImage
      },
      {
        id: 3,
        unique_key: getUniqueKey(),
        title: 'Logo',
        name: 'Logo',
        slug: 'logo',
        image: logoImage || QUOTE_PROPOSAL_IMAGES.logo
      }
    ]
  };
  return data;
};

export const contractModalValidationSchema = Yup.object({
  contract_type_id: Yup.string().required('Contract Type is required'),
  recipient_details: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string()
      .email('Invalid email address')
      .required('Email is required')
      .test(
        'unique-email',
        'Email must be unique',
        function (item, testContext) {
          const { path, parent, options } = testContext;
          const recipient = options?.context?.recipient_details;
          if (recipient?.filter((detail: any) => detail?.email === item).length > 1) {
            return false;
          }
          return true;
        }
      ),
      is_signer: Yup.number().test(
        'signer-checks',
        function (_item, testContext) {
          const { options } = testContext;
          const recipients = options?.context?.recipient_details || [];
          const signerCount = recipients.filter((recipient: { is_signer: number; }) => recipient.is_signer).length;
          const errorMessage = [];
      
          if (signerCount < 1) {
            errorMessage.push('At least one signature is required.');
          }
      
          if (signerCount > 2) {
            errorMessage.push('No more than two signatures can be selected.');
          }
      
          if (recipients.length === 4 && signerCount < 2) {
            errorMessage.push('At least two signatures must be selected when there are four recipients.');
          }
      
          return errorMessage.length === 0 || this.createError({ message: errorMessage[0] });
        }
      )
      ,
    })
  ),
  fdd_rep: Yup.string().required('Food dudes rep is required'),
  is_deposit_percent: Yup.boolean(),
  deposit_percent: Yup.number()
    .max(100, 'Percent should not be more than 100%')
    .when('is_deposit_percent', {
      is: true,
      then: Yup.number().required('Deposit percent is required when using percentage')
    })
    .nullable(),
  deposit_amount: Yup.number()
    .max(Yup.ref('grand_total'), ({ max }) => `Contract amount should not be more than ${max}`)
    .when('is_deposit_percent', {
      is: false,
      then: Yup.number().required('Deposit amount is required when using amount')
    }),
  grand_total: Yup.number().transform((originalValue) => {
    return Math.round(originalValue);
  })
});

export const imagePreviewStyle = {
  width: '150px', 
  height: '150px', 
  // border: "1px solid black",
  // padding: "4px",
  // backgroundColor: "#f8f8f8",
}

export const RECIPIENT_DETAILS = {
  email: "",
  name: "",
  is_signer: 0,
  localId: Math.random()
};