import React, { Component, ReactNode } from 'react';
import _ from 'lodash';
import FallbackErrorPage from '@atom/FallbackError';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };

    _.bindAll(this, 'onRedirect');
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ hasError: true, error });
  }

  onRedirect(): void {
    this.setState(
      {
        hasError: false,
        error: null
      },
      () => {
        window.history.back();
      }
    );
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return <FallbackErrorPage error={error ? error.message : ''} />;
    }

    return children;
  }
}

export default ErrorBoundary;
