import { Col, Divider, Row } from 'antd';
import FormDataView, { Title } from '@atom/FormDataView';
import { VenueAddEditDummyData, VenueKitchenDetails } from '../helper';
import { useNavigate, useParams } from 'react-router';

import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { IVenueDataType } from '../types';
import PageLayout from '@layout/PageLayout';
import StatusLabel from '@atom/StatusLabel';
import moment from 'moment';
import { useState } from 'react';

function VenueInfo({ VenueData }: { VenueData: IVenueDataType }) {
  const getStatus = (status: string | number) => {
    if (status == 0) return 'No';
    else if (status == 1) return 'Yes';
    else return null;
  };
  const getFees = (fees: string | boolean | undefined) => {
    if (!fees) return null;
    if (fees == '0' || fees == '0.00') return null;
    else return `${fees} %`;
  };
  return (
    <Flex direction="column">
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={6} sm={6} xs={12}>
          <FormDataView heading={'VENUE NAME'} value={VenueData?.venue_name} />
        </Col>
        <Col span={6} md={6} sm={6} xs={12}>
          <FormDataView heading={'VENUE SUBSECTION'} value={VenueData?.venue_sub_section} />
        </Col>
        <Col span={6} md={6} sm={6} xs={12}>
          <FormDataView heading={'VENUE ADDRESS'} value={VenueData?.venue_address} />
        </Col>
        <Col span={6} md={6} sm={6} xs={12}>
          {/* <FormDataView heading={'STATUS'} value={VenueData?.status} /> */}
          <Title>STATUS</Title>
          {(!!VenueData?.status || VenueData?.status === 0) && (
            <StatusLabel status={VenueData?.status} />
          )}
        </Col>
      </Row>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={6} sm={6} xs={12}>
          <FormDataView
            heading={'VENUE AVAILABILITY'}
            value={
              !VenueData?.start_time || !VenueData?.end_time
                ? null
                : `${VenueData?.start_time} - ${VenueData?.end_time}`
            }
          />
        </Col>
        <Col span={6} md={6} sm={6} xs={12}>
          <FormDataView heading={'VENUE CAPACITY'} value={VenueData?.capacity} />
        </Col>
        <Col span={6} md={6} sm={6} xs={12}>
          <FormDataView heading={'FD ACCOUNT MANAGER'} value={VenueData?.account_manager?.name} />
        </Col>
        <Col span={6} md={6} sm={6} xs={12}>
          <FormDataView
            heading={'REGISTRATION DATE'}
            value={moment(VenueData?.registered_at).format('ddd, MMMM DD')}
          />
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={12} md={12} sm={12} xs={12}>
          <FormDataView
            heading={'STAFF ENTRANCE AND PARKING'}
            value={VenueData?.venue_detail?.parking_additional_information}
          />
        </Col>
      </Row>
      <H2Typography style={{ marginTop: '15px' }}>Additional Contacts</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      {VenueData?.additional_contacts?.map((contact, index) => (
        <Row
          gutter={8}
          style={{ width: '100%' }}
          key={`${contact?.contact_name}-${contact?.contact_email}-${index}`}
        >
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView
              heading={'VENUE CONTACT'}
              value={VenueData?.additional_contacts?.[index]?.contact_name}
            />
          </Col>
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView
              heading={'CONTACT EMAIL'}
              value={VenueData?.additional_contacts?.[index]?.contact_email}
            />
          </Col>
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView
              heading={'CONTACT PHONE NUMBER'}
              value={
                VenueData?.additional_contacts?.[index]?.formated_contact_phone_number
                  ? `${VenueData?.additional_contacts?.[index]?.country_code ?? ''} ${
                      VenueData?.additional_contacts?.[index]?.formated_contact_phone_number
                    } ${
                      !!VenueData?.additional_contacts?.[index]?.extension
                        ? `x ${VenueData?.additional_contacts?.[index]?.extension}`
                        : ''
                    }`
                  : null
              }
            />
          </Col>
        </Row>
      ))}
      <H2Typography style={{ marginTop: '15px' }}>Kitchen Details</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={8} sm={8} xs={12}>
          <FormDataView
            heading={'POWER'}
            value={getStatus(VenueData?.venue_kitchen_detail?.power_available ?? '')}
          />
        </Col>
        <Col span={6} md={8} sm={8} xs={12}>
          <FormDataView
            heading={'RUNNING WATER'}
            value={getStatus(VenueData?.venue_kitchen_detail?.running_water ?? '')}
          />
        </Col>
        <Col span={6} md={8} sm={8} xs={12}>
          <FormDataView
            heading={'KITCHEN AVAILABILITY'}
            value={
              VenueKitchenDetails?.find(
                (item) => item.value == VenueData?.venue_kitchen_detail?.kitchen_availability
              )?.label
            }
          />
        </Col>
      </Row>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'IN HOUSE COOKING EQUIPMENT'}
            value={VenueData?.venue_kitchen_detail?.cooking_equipments}
          />
        </Col>
      </Row>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'SPECIAL RENTAL EQUIPMENT'}
            value={VenueData?.venue_kitchen_detail?.special_equipments}
          />
        </Col>
      </Row>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'CATERING RENTALS REQUIRED'}
            value={VenueData?.venue_kitchen_detail?.rental_equipments}
          />
        </Col>
      </Row>
      <H2Typography style={{ marginTop: '15px' }}>Alcohol & Bar Set Up</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'VENUE IN CHARGE'}
            value={getStatus(VenueData?.venue_detail?.alcohol_bar_venue_in_charge ?? '')}
            textColor={VenueData?.venue_detail?.alcohol_bar_venue_in_charge ? '#4e73df' : '#2f80ed'}
          />
        </Col>
      </Row>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'BAR SERVICE AND REQUIREMENTS'}
            value={VenueData?.venue_detail?.alcohol_bar_setup_requirements}
          />
        </Col>
      </Row>
      <H2Typography style={{ marginTop: '15px' }}>Set Up Duties</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'SET UP REQUIREMENTS'}
            value={VenueData?.venue_detail?.setup_duty_requirements}
          />
        </Col>
      </Row>

      <H2Typography style={{ marginTop: '15px' }}>Closing Duties</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'Closing REQUIREMENTS'}
            value={VenueData?.venue_detail?.closing_duty_requirements}
          />
        </Col>
      </Row>

      <H2Typography style={{ marginTop: '15px' }}>Loading Dock & Parking Information</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={6} sm={8} xs={24}>
          <FormDataView
            heading={'LOADING DOCK'}
            value={getStatus(VenueData?.venue_detail?.loading_dock ?? '')}
          />
        </Col>
        <Col span={6} md={6} sm={8} xs={24}>
          <FormDataView
            heading={'PARKING'}
            value={getStatus(VenueData?.venue_detail?.parking ?? '')}
            textColor={VenueData?.venue_detail?.parking ? '#2f80ed' : '#2f80ed'}
            titleColor={VenueData?.venue_detail?.parking ? '#2f80ed' : '#2f80ed'}
          />
        </Col>
      </Row>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'ADDITIONAL INFORMATION'}
            value={VenueData?.venue_detail?.loading_parking_additional_information}
          />
        </Col>
      </Row>

      <H2Typography style={{ marginTop: '15px' }}>Service Information</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={6} sm={8} xs={12}>
          <FormDataView
            heading={'COAT CHECK'}
            value={getStatus(VenueData?.venue_detail?.coat_check ?? '')}
          />
        </Col>
      </Row>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'ADDITIONAL INFORMATION'}
            value={VenueData?.venue_detail?.coat_check_additional_information}
          />
        </Col>
      </Row>

      <H2Typography style={{ marginTop: '15px' }}>Staff Details</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'ADDITIONAL RESTRICTIONS'}
            value={VenueData?.venue_detail?.staff_additional_restriction}
          />
        </Col>
      </Row>

      <H2Typography style={{ marginTop: '15px' }}>Landmark Fee</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      {VenueData?.venue_detail?.on_net_total ? (
        <Row gutter={8} style={{ width: '100%' }}>
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView
              heading={'NET TOTAL'}
              value={
                VenueData?.venue_detail?.landmark_fees &&
                `${VenueData?.venue_detail?.landmark_fees}%`
              }
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={8} style={{ width: '100%' }}>
          <Col span={6} md={8} sm={8} xs={24}>
            <FormDataView heading={'FOOD'} value={getFees(VenueData?.venue_detail?.food_fees)} />
          </Col>
          <Col span={6} md={8} sm={8} xs={24}>
            <FormDataView
              heading={'ALCOHOL & SOFT BAR'}
              value={getFees(VenueData?.venue_detail?.alcohol_soft_bar_fees)}
            />
          </Col>
          <Col span={6} md={8} sm={8} xs={24}>
            <FormDataView
              heading={'LABOUR'}
              value={getFees(VenueData?.venue_detail?.labour_fees)}
            />
          </Col>
        </Row>
      )}

      <H2Typography style={{ marginTop: '15px' }}>Additional Notes</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={6} md={24} sm={24} xs={24}>
          <FormDataView
            heading={'ADDITIONAL NOTES'}
            value={VenueData?.venue_detail?.additional_notes}
          />
        </Col>
      </Row>
    </Flex>
  );
}

export default VenueInfo;