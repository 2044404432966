import { useState } from 'react';

import HorizontalTab from '@molecules/HorizontalTabs';
import { SETTING_TAB, settingsGlobalTabValue } from './helper';

const Settings = () => {
  const [currentChildTab, setCurrentChildTab] = useState<string>(SETTING_TAB.HST_FEES);

  const onTabChange = (tab: string) => {
    setCurrentChildTab(tab);
  };

  return (
    <HorizontalTab
      value={settingsGlobalTabValue()}
      activeKey={currentChildTab}
      onChange={onTabChange}
    />
  );
};

export default Settings;
