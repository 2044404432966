import services from '@services/index';
import * as yup from 'yup';

//Constants
export enum INPUT_TYPE {
  RADIO_INPUT = 1,
  TEXT_INPUT = 2,
  RATE = 3
}

export interface ResponseType {
  data: any;
  error: string | null;
  message?: string;
}

export const initialEventDetails = {
  event_detail: {},
  event_head_chef_detail: [],
  event_manager_detail: []
};

//Format
export const getIntitialValue = (value: any) => {
  let questions: any = [];
  value?.forEach((section: any) => {
    const formatedQuestions = section?.questions?.map((q: any, index: number) => {
      return {
        ...q,
        question_id: q.id,
        [`${q.id}_response`]: null,
        [`${q.id}_text`]: ''
      };
    });
    questions = [...questions, ...formatedQuestions];
  });
  return questions;
};

export const getFormatedSections = (value: any) => {
  const formatedValue = value?.map((section: any) => {
    return {
      ...section,
      questions: section?.questions?.map((q: any) => {
        return {
          ...q,
          question_id: q.id,
          [`${q.id}_response`]: null,
          [`${q.id}_text`]: ''
        };
      })
    };
  });
  return formatedValue;
};

export const getFormatedData = (values: Record<string, string | number>) => {
  const formatedData: FormattedDatum[] = [];

  Object.keys(values)?.forEach((key) => {
    const parts = key.split('_');
    const question_id = parseInt(parts[0]);
    const property = parts[1];

    if (property === 'response') {
      if (!formatedData[question_id - 1]) {
        formatedData[question_id - 1] = { question_id };
      }
      formatedData[question_id - 1].response = values[key] as number;
    } else if (property === 'text') {
      if (!formatedData[question_id - 1]) {
        formatedData[question_id - 1] = { question_id };
      }
      formatedData[question_id - 1].text = values[key] as string;
    }
  });

  return formatedData;
};

//API
export const getSurveyQuestion = async (isAdmin?: boolean, type?: string) => {
  const url = isAdmin && type ? `/survey/questions?type=${type}` : `/survey/questions`;
  try {
    const { data } = (await services.get(url)) as {
      data: { data: any };
    };
    return data?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getEventDetails = async (token: string, isAdmin?: boolean, type?: string) => {
  try {
    const url =
      isAdmin && type
        ? `/survey/event-details/${token}?type=${type}`
        : `/survey/event-details/${token}`;
    const {
      data: { data }
    } = (await services.get(url)) as {
      data: { data: any };
    };
    return data;
  } catch (err: any) {
    return {
      status: err?.status,
      error: err && err?.error ? err?.error?.error : 'Something went wrong!'
    };
  }
};

export const getContractViewDetails = async (token: string, id: string) => {
  try {
    const res = (await services.get(`/contract/verify-token/${token}/${id}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const submitSurveyForm = async (body: any, isAdmin?: boolean, type?: string): Promise<ResponseType> => {
  try {
    const url =  isAdmin && type ?  `/survey?type=${type}` : `/survey`;
    const res = (await services.put(url, body)) as any;

    return { data: res?.data?.data, error: null, message: res?.data?.message };
  } catch (error: any) {
    return {
      data: null,
      error: error.error.error.survey_url[0] || 'Something went wrong!'
    };
  }
};