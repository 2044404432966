import styled from 'styled-components';

export const UserEmailWrapper = styled.div`
  .email-list {
    margin: 0;
    padding: 16px 30px;
    fontsize: 16;
  }

  @media (max-width: 767px) {
    .email-list {
      padding: 16px 0px;
    }
  }
`;
