import { Col, Row, Spin } from 'antd';
import { IAdditionalContacts, IVenueDataType } from '../types';
import React, { useEffect, useState } from 'react';
import { VENUE_DETAILS_TABS, changeVenueStatus, getVenue } from '../helper';
import { showErrorToast, showToast } from '@utils/index';
import { useLocation, useNavigate, useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import ConfirmModal from '@organism/ConfimationModal';
import DeactivateIcon from '@assets/alert-circle.svg';
import EditIcon from '@assets/edit-2.svg';
import HorizontalTab from '@molecules/HorizontalTabs';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import ReactivateIcon from '@assets/alert-circle-red.svg';
import { VENUE_MANAGEMENT_TABS } from '../ListViewPages/helper';
import VenueDocuments from './VenueDocuments';
import VenueInfo from './VenueInfo';
import { getUniqueDomId } from '@fullcalendar/core/internal';
import { useSelector } from 'react-redux';

function DetailsViewPages() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState<boolean>(false);
  const [isReactivateModalVisible, setIsReactivateModalVisible] = useState<boolean>(false);
  const [tabType, setTabType] = useState(VENUE_DETAILS_TABS.INFO);
  const [venueData, setVenueData] = useState<IVenueDataType>({});
  const [isLoadingVenue, setIsLoadingVenue] = useState(true);

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    getVenueData();
  }, [id]);

  const getVenueData = async () => {
    setIsLoadingVenue(true);
    try {
      const res = await getVenue(id);
      if (res?.success) {
        const documentsList = res?.data?.documents?.map((doc: any) => {
          doc.uid = getUniqueDomId();
          doc.name = doc.document_name;
          doc.url = doc.document_url;
          return doc;
        });
        let additionalContacts = [];
        if (res?.data?.additional_contacts?.length > 0) {
          additionalContacts = res?.data?.additional_contacts?.map(
            (contact: IAdditionalContacts) => {
              return {
                ...contact,
                country_code:
                  !!contact?.country_code && !contact?.country_code?.includes('+')
                    ? `+${contact?.country_code}`
                    : contact?.country_code
              };
            }
          );
        }
        setVenueData(
          { ...res?.data, documents: documentsList, additional_contacts: additionalContacts } 
        );
      }
    } catch (error) {}
    setIsLoadingVenue(false);
  };

  const changeStatusMethod = async (text: 'Deactivated' | 'Reactivated') => {
    if (!id) return;
    setIsLoadingVenue(true);
    const res = await changeVenueStatus(id);
    if (res?.success) {
      setIsLoadingVenue(false);
      setIsReactivateModalVisible(false);
      setIsDeactivateModalVisible(false);
      showToast({ message: 'Success!', description: `Venue ${text}` });
      navigate(`/venues/tab/${state}`);
    } else {
      setIsLoadingVenue(false);
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
      // navigate(
      //   `/venues/tab/${
      //     text === 'Deactivated' ? VENUE_MANAGEMENT_TABS.ACTIVE : VENUE_MANAGEMENT_TABS.INACTIVE
      //   }`
      // );
    }
  };

  const getTabs = () => {
    const tabValues = [
      {
        tab: 'INFO',
        content: <VenueInfo VenueData={venueData} />,
        key: VENUE_DETAILS_TABS.INFO,
        getUrl: VENUE_DETAILS_TABS.INFO
      },
      {
        tab: 'DOCUMENTS',
        content: <VenueDocuments VenueData={venueData} />,
        key: VENUE_DETAILS_TABS.DOCUMENTS,
        getUrl: VENUE_DETAILS_TABS.DOCUMENTS
      }
    ];
    return tabValues;
  };

  const getButton = (status: number) => {
    switch (status) {
      case 0:
        return (
          <Button
            variant="ghost"
            text={'REACTIVATE'}
            icon={<img src={ReactivateIcon} alt="REACTIVATE" style={{ color: 'red' }} />}
            onClick={() => setIsReactivateModalVisible(true)}
            style={{ color: 'red' }}
          />
        );
      case 1:
        return (
          <Button
            variant="ghost"
            text={'DEACTIVATE'}
            icon={<img src={DeactivateIcon} alt="Deactivate" />}
            onClick={() => setIsDeactivateModalVisible(true)}
          />
        );

      default:
        return null;
    }
  };

  if (isLoadingVenue) {
    return <CenterSpiner />;
  }

  return (
    <PageLayout title={'Venue Manaegment'}>
      <PageTitle
        text="VENUE MANAGEMENT"
        isBottom={true}
        bordered={true}
        isBack={true}
        path={`/venues/tab/${VENUE_MANAGEMENT_TABS.ALL}`}
      >
        {isAdmin && (
          <>
            {(!!venueData?.status || venueData?.status === 0) &&
              getButton(Number(venueData?.status))}
            <Button
              variant="ghost"
              text="Edit"
              icon={<img src={EditIcon} alt="Edit" />}
              onClick={() => navigate(`/venues/${id}/edit`)}
            />
          </>
        )}
      </PageTitle>
      <Row gutter={24}>
        <Col span={24}>
          <HorizontalTab
            value={getTabs()}
            activeKey={tabType}
            onChange={(tab: any) => {
              setTabType(tab);
              // let newurl = `/venues/tab/${tab}`;
              // navigate(newurl);
            }}
          />
        </Col>
      </Row>
      <ConfirmModal
        title="Are you sure you want to Deactivate this Venue?"
        okText="Deactivate"
        cancelText="Cancel"
        visible={isDeactivateModalVisible}
        onOK={() => changeStatusMethod('Deactivated')}
        onCancel={() => setIsDeactivateModalVisible(false)}
      >
        This Venue will be tagged as "INACTIVE"
      </ConfirmModal>
      <ConfirmModal
        title="Are you sure you want to Reactivate this Venue?"
        okText="Reactivate"
        cancelText="Cancel"
        visible={isReactivateModalVisible}
        onOK={() => changeStatusMethod('Reactivated')}
        onCancel={() => setIsReactivateModalVisible(false)}
      >
        This Venue will be tagged as "ACTIVE"
      </ConfirmModal>
    </PageLayout>
  );
}

export default DetailsViewPages;
