import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import { Flex } from '@atom/Flex';
import { H2Typography, H3Typography } from '@constant/typography/Typography';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { showErrorToast, showToast } from '@utils/index';
import { Col, Divider, Row } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import QuestionItem from './QuestionItem';
import ViewEventDetails from './ViewEventDetails';
import {
  INPUT_TYPE,
  getEventDetails,
  getFormatedData,
  getFormatedSections,
  getIntitialValue,
  getSurveyQuestion,
  initialEventDetails,
  submitSurveyForm
} from './helper';
import { SurveyPageWrapper } from './style';
import ScrollToFieldError from '@utils/formikFocus';
import {
  EventContentWrapper,
  SectionTitleDiv
} from '@pages/QuoteManagementPage/ViewQuoteDetails/style';

function SurveyForm() {
  const navigate = useNavigate();
  const params = useParams();
  const { token, type } = params as { token: string; type: string };
  const [initialState, setInitialState] = useState<SurveyFormState>({ questions: [] });
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState<Section[]>([]);
  const [eventDetails, setEventDetails] = useState<EventDetailsType>(initialEventDetails);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [surveyErrorMessage, setSurveyErrorMessage] = useState<string>('');
  
  const user = useSelector(
    (state: { user: { isEmployee: boolean; isAdmin: boolean } }) => state.user
  );

  const { isAdmin } = user;

  const onSubmitHandler = async (values: any) => {
    const formatedValues = getFormatedData(values);

    setLoading(true);
    const updatedValues = formatedValues?.map((question: any) => {
      delete question?.created_at;
      delete question?.deleted_at;
      delete question?.id;
      delete question?.question;
      delete question?.question_order;
      delete question?.section;
      delete question?.section_order;
      delete question?.status;
      delete question?.type;
      delete question?.updated_at;
      return question;
    });

    const body = {
      event_id: eventDetails?.event_detail?.event_id || '',
      question: updatedValues
    };

    const data = await submitSurveyForm({ ...body }, isAdmin, type);
    setLoading(false);
    if (!data.error) {
      showToast({ message: data?.message || 'Survey Submitted Successfully', description: '' });
      navigate('/');
    } else {
      showErrorToast({
        message: 'Error Saving Survey Form',
        description: data.error || 'Please try again'
      });
      setSurveyErrorMessage(data.error);
    }
  };

  const extractStringFromError = (error: ErrorObject) => {
    const value = Object?.values(error)?.[0];
    const errorMessage = value[0];
    if (typeof errorMessage === 'string') {
      return errorMessage;
    }
  };

  const validationSchema = yup.object().shape(
    initialState?.questions?.reduce((schema: any, field: SchemaQuestionItemTypes) => {
      const { id, type } = field;

      switch (type) {
        case INPUT_TYPE.RADIO_INPUT:
          schema[`${id}_response`] = yup.number().required('This field is required').nullable();
          schema[`${id}_text`] = yup.string().nullable();
          break;
        case INPUT_TYPE.RATE:
          schema[`${id}_response`] = yup.number().required('This field is required').nullable();
          schema[`${id}_text`] = yup.string().nullable();
          break;
        default:
          schema[`${id}_response`] = yup.number().nullable();
          schema[`${id}_text`] = yup.string().nullable();
          break;
      }

      return schema;
    }, {})
  );

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnMount: false,
    onSubmit: onSubmitHandler,
    enableReinitialize: true
  });

  const { handleSubmit, submitCount, setFieldTouched } = formik;

  const handleTouched = () => {
    //this function is created to make the radio button touch active to show error
    initialState?.questions?.forEach((fieldName) => {
      const fieldNames = Object.keys(fieldName);
      fieldNames?.forEach((field) => {
        setFieldTouched(field, true);
      });
    });
  };

  const fetchQuestion = async () => {
    const sectionData = await getSurveyQuestion(isAdmin, type);
    if (sectionData?.sections) {
      const { sections } = sectionData;
      const formatedSections = await getFormatedSections(sections);
      const questions = await getIntitialValue(sections);
      setSections(formatedSections);
      setInitialState({ questions });
    } else {
      return showErrorToast({
        message: `Failed`,
        description: 'Please try again'
      });
    }
  };

  const fetchEventData = async () => {
    const eventData = await getEventDetails(token, isAdmin, type);
    if (eventData?.error) {
      const errorMessage = extractStringFromError(eventData?.error);
      if (eventData?.status === 422 && errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      return showErrorToast({
        message: `Failed`,
        description: errorMessage || 'Something went wrong!'
      });
    } else {
      setEventDetails(eventData);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await fetchEventData();
        await fetchQuestion();
        setLoading(false);
      } catch (error) {
        // Handle error
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <>
        <CenterSpiner />
      </>
    );
  }

  if (
    !sections?.length ||
    !(Object.keys(eventDetails?.event_detail).length > 0) ||
    surveyErrorMessage
  ) {
    return (
      <PageLayout>
        <Flex
          style={{ height: 400 }}
          direction="column"
          justifyContent="center"
          gap={10}
          alignItems="center"
        >
          <H3Typography>{surveyErrorMessage || errorMessage}</H3Typography>
          <Button text="DASHBOARD" variant="secondary" onClick={() => navigate('/')} />
        </Flex>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <ScrollToFieldError formik={formik} />
      <SurveyPageWrapper>
        <div style={{ marginTop: '15px' }}>
          <PageTitle className="pageTitle" text="EVENT FOLLOW UP" />
        </div>
        <Row gutter={24}>
          <Col span={24}>
            <ViewEventDetails {...eventDetails} />
          </Col>
        </Row>
        <FormikProvider value={formik}>
          <Row style={{ marginTop: 24 }} gutter={24}>
            <Col span={24}>
              {sections?.map((section: Section) => (
                <React.Fragment key={section.section_title}>
                  <Row gutter={16} style={{ marginTop: '20px' }}>
                    <Col span={24}>
                      <SectionTitleDiv>
                        <H2Typography className={'event-section'}>
                          {section.section_title}
                        </H2Typography>
                      </SectionTitleDiv>
                    </Col>
                  </Row>
                  <EventContentWrapper style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    <Row style={{ width: '100%' }} gutter={[16, 10]}>
                      {section?.questions?.map((question) => {
                        return (
                          <Col lg={8} key={question.id}>
                            <QuestionItem {...question} formik={formik} submitCount={submitCount} />
                          </Col>
                        );
                      })}
                    </Row>
                  </EventContentWrapper>
                </React.Fragment>
              ))}
            </Col>
          </Row>
        </FormikProvider>
        <Flex gap={8} justifyContent="flex-end">
          <Row gutter={[12, 12]}>
            <Col lg={12} xs={24}>
              <Button fullWidth text="Cancel" variant="secondary" onClick={() => navigate('/')} />
            </Col>
            <Col lg={12} xs={24}>
              <Button
                fullWidth
                text="Submit"
                onClick={() => {
                  handleSubmit();
                  handleTouched();
                }}
              />
            </Col>
          </Row>
        </Flex>
      </SurveyPageWrapper>
    </PageLayout>
  );
}

export default SurveyForm;
