import { Space } from 'antd';
import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import {
  DropdownContainer,
  GhostDropdownButtonContainer,
  PrimaryCustomMenu,
  PrimaryDropdownButtonContainer,
  SecondaryCustomMenu,
  SecondaryDropdownButtonContainer
} from './style';

export const enum ICON_POSITION {
  START = 'start',
  END = 'end'
}

export const enum BUTTON_VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST = 'ghost'
}

const getButtonType = (type: any) => {
  switch (type) {
    case BUTTON_VARIANT.PRIMARY:
      return PrimaryDropdownButtonContainer;
    case BUTTON_VARIANT.SECONDARY:
      return SecondaryDropdownButtonContainer;
    case BUTTON_VARIANT.GHOST:
      return GhostDropdownButtonContainer;
    default:
      return PrimaryDropdownButtonContainer;
  }
};

const getMenuType = (type: any) => {
  switch (type) {
    case BUTTON_VARIANT.PRIMARY:
      return PrimaryCustomMenu;
    case BUTTON_VARIANT.SECONDARY:
      return SecondaryCustomMenu;
    case BUTTON_VARIANT.GHOST:
      return SecondaryCustomMenu;
    default:
      return PrimaryCustomMenu;
  }
};

interface DropdownButtonProps {
  text: string;
  options: Array<{
    label: string;
    key: any;
  }>;
  onSelect: (item: any) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: ICON_POSITION.START | ICON_POSITION.END;
  variant?: BUTTON_VARIANT.PRIMARY | BUTTON_VARIANT.SECONDARY | BUTTON_VARIANT.GHOST;
}

function DropdownButton(props: DropdownButtonProps) {
  const {
    text,
    options,
    onSelect,
    disabled,
    icon,
    iconPosition = ICON_POSITION.END,
    variant
  } = props;

  const ButtonTag = getButtonType(variant);

  const MenuTag = getMenuType(variant);

  const menu = (
    <MenuTag
      onClick={(item: any) => {
        onSelect(item);
      }}
      items={options}
    />
  );

  return (
    <DropdownContainer disabled={disabled} overlay={menu} trigger={['click']}>
      <ButtonTag>
        <Space>
          {iconPosition === ICON_POSITION.START && icon}
          {text}
          {iconPosition === ICON_POSITION.END &&
            (icon || <DownOutlined style={{ fontSize: 13, fontWeight: 900 }} />)}
        </Space>
      </ButtonTag>
    </DropdownContainer>
  );
}

export default DropdownButton;
