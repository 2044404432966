import { Rate as CustomRate } from 'antd';

function Rate({
  onChange,
  value,
  id,
}: {
  onChange: (value: number) => void;
  value: number;
  id: string;
}) {
  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

  return <CustomRate id={id} tooltips={desc} onChange={onChange} value={value} />;
}

export default Rate;
