import {
  AccountManagerDropdownObjectType,
  CustomerDropdownObjectType,
  VenueContactsDropdownObjectType,
  VenueDropdownObjectType
} from '@pages/QuoteManagementPage/types';
import { Col, Row, Select } from 'antd';
import {
  FORM_INITIAL_VALUES,
  fetchCustomersList,
  fetchManagers,
  fetchVenueDetails,
  fetchVenuesList,
  validationSchema,
  convertDateForQuoteDate,
  addCustomer
} from './helper';
import { GetTabData, PutTabData } from '@services/quoteServices';
import { QuoteBasicDetailsObject, getEventDetails, getTabBaseUrl } from '../helper';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AddEditQuoteFooter from '../AddEditQuoteFooter';
import CenterSpiner from '@atom/CenterSpiner';
import DatePickerComponent from '@atom/DatePicker';
import { H2Typography } from '@constant/typography/Typography';
import { Input } from '@atom/Input';
import { Label } from '@atom/FormLable';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import SelectInput from '@atom/Select';
import TimePickerComponent from '@atom/TimePicker';
import moment, { Moment } from 'moment';
import { showErrorToast, showToast } from '@utils/index';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import ScrollToFieldError from '@utils/formikFocus';
import AddNewCustomerModal from './AddNewCustomerModal';
import AddNewVenueModal from './AddNewVenueModal';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';
import InputPhone from '@atom/InputPhone';
import Checkbox from '@atom/Checkbox';
import { ContactDetailsType } from '@pages/VenueManagementPage/types';
import { updateDocumentList } from '@pages/EventManagementPage/EventWithExistingQuote/EventWithExistingQuoteTabPanels/DocuementsTab/helper';
import services from '@services/index';

interface CustomerFormProps {
  tabKey: string;
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  isEvent?: boolean;
  submitForm?: (tabKey: string, values: any, actions: any) => void;
}

const CustomerForm = (props: CustomerFormProps) => {
  const { tabKey, quoteBasicDetails, setQuoteBasicDetails, isEvent, submitForm } = props;
  const params = useParams();
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);
  const { quoteId } = params;
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAccMan, setLoadingAccMan] = useState<boolean>(true);
  const [loadingCusList, setLoadingCusList] = useState<boolean>(true);
  const [loadingVenList, setLoadingVenList] = useState<boolean>(true);
  const [loadingCreateQuote, setLoadingCreateQuote] = useState<boolean>(false);
  const [draftSubmitting, setDraftSubmitting] = useState(false);
  const [formikData, setFormikData] = useState(FORM_INITIAL_VALUES);
  const [accountManagersList, setAccountManagersList] = useState<
    AccountManagerDropdownObjectType[]
  >([]);
  const [customersList, setCustomersList] = useState<CustomerDropdownObjectType[]>([]);
  const [venuesList, setVenuesList] = useState<VenueDropdownObjectType[]>([]);
  const [venueContactsList, setVenueContactsList] = useState<VenueContactsDropdownObjectType[]>([]);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState<boolean>(false);
  const [isVenueModalVisible, setIsVenueModalVisible] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: formikData,
    validationSchema: validationSchema(isEvent),
    onSubmit: (values: any, actions: any) => {
      submitHandler(values, actions);
    }
  });
  const {
    touched,
    errors,
    values,
    setValues,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit
  } = formik;

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (!isDraft && submitForm) {
      submitForm(tabKey, values, actions);
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      ...values,
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string
    });
    if (res?.success) {
      actions?.setSubmitting(false);
      setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
        ...prev,
        id: res?.data?.id || prev?.id,
        quotation_no: res?.data?.quotation_no || prev?.quotation_no,
        status: res?.data?.status,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : res?.data?.unique_invoice_string || prev?.unique_invoice_string
      }));
      const tabBaseUrl = getTabBaseUrl(
        location?.pathname,
        res?.data?.id || quoteId,
        quoteBasicDetails,
        res?.data?.id
      );
      navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}venue`, {
        state: {
          prevPath: location?.pathname,
          eventId: values?.event_id,
          path: location?.state?.path
        }
      });
      if (isDraft) {
        showToast({
          message: 'Success!',
          description: 'Quotation saved as Draft'
        });
      }
    } else {
      actions?.setSubmitting(false);
      actions?.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
  };

  const getCustomerTabInitialDetails = async (id: number | string) => {
    setLoading(true);
    try {
      const res: any = await GetTabData(tabKey, id, isEvent);
      if (res?.success) {
        setFormikData(res?.data);
        setValues({
          ...values,
          ...res?.data,
          adult_count: !!res?.data?.adult_count ? res?.data?.adult_count : '',
          kids_count: !!res?.data?.kids_count ? res?.data?.kids_count : '',
          vendor_staff_count: !!res?.data?.vendor_staff_count ? res?.data?.vendor_staff_count : '',
          from_event: 0
        });
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
        if (res?.data?.venue_id) {
          await fetchVenueDetails(res?.data?.venue_id, setVenueContactsList);
        }
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch quote data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch quote data.'
      });
    }
    setLoading(false);
  };

  const backHandler = () => {
    navigate(-1);
  };
  const cancelHandler = () => {
    if (isEvent) {
      navigate('/events', {
        state: {
          prevPath: location?.pathname,
          path: location?.state?.path
        }
      });
    } else {
      navigate(`/quote/tab/${QUOTE_MANAGEMENT_TABS.ALL}`, {
        state: {
          prevPath: location?.pathname,
          path: location?.state?.path
        }
      });
    }
  };
  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    // if (
    //   !customersList.length ||
    //   (!isEvent && !venuesList.length) ||
    //   (!isEvent && !venueContactsList?.length)
    // )
    if (!customersList.length) {
      showErrorToast({
        message: 'Failed!',
        description: 'Please fill all required field'
      });
    }
    !quoteId && setFieldValue('status', STATUS.DRAFT);
    handleSubmit();
  };

  const calculateTotalNumberOfGuestsHandler = (
    adult_count?: number | string,
    kids_count?: number | string,
    vendor_staff_count?: number | string
  ) => {
    if (!errors?.adult_count && !errors?.kids_count && !errors?.vendor_staff_count) {
      const totalGuests: number =
        Number(adult_count || '0') + Number(kids_count || '0') + Number(vendor_staff_count || '0');
      setFieldValue('total_guest', totalGuests);
    }
  };

  useEffect(() => {
    (async () => {
      if (tabKey === 'customer') {
        setLoading(true);
        fetchManagers('', setAccountManagersList, setLoadingAccMan);
        const customers = await fetchCustomersList('', setCustomersList, setLoadingCusList);
        await fetchVenuesList('', setVenuesList, setLoadingVenList);
        if (quoteId) {
          await getCustomerTabInitialDetails(quoteId);
        } else {
          const customerId: string = location?.search?.includes('?customer_id=')
            ? location?.search?.replace('?customer_id=', '')
            : '';
          customerId && setFieldValue('customer_id', Number(customerId));
          const test = customers?.find((customer: any) => customer?.id === Number(customerId));

          setFieldValue(`customer_on_site_name`, test?.text);
          setFieldValue(`customer_on_site_email`, test?.email);
          setFieldValue(`customer_on_site_country_code`, test?.country_code);
          setFieldValue(`customer_on_site_phone_number`, test?.phone_number);
          setFieldValue(`customer_on_site_extension`, test?.extension);

          setLoading(false);
        }
        setLoading(false);
      }
    })();
  }, [tabKey]);

  if (loading || loadingAccMan || loadingCusList || loadingVenList) {
    return <CenterSpiner />;
  }

  const addNewCustomerHandler = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    try {
      const res: any = await addCustomer(values);
      if (res?.success) {
        showToast({
          message: 'Success!',
          description: 'Customer saved'
        });
        const data = res?.data?.data;
        setCustomersList([{ id: data?.id, text: data?.name }, ...customersList]);
        setFieldValue('customer_id', data?.id);
        setFieldValue('customer_on_site_name', data?.name);
        setFieldValue('customer_on_site_email', data?.email);
        setFieldValue(`customer_on_site_country_code`, data?.country_code);
        setFieldValue(`customer_on_site_extension`, data?.extension);
        setFieldValue(`customer_on_site_phone_number`, data?.phone_number);
        setFieldValue('is_customer_on_site_same_as_customer_primary_contact', true);
        setIsCustomerModalVisible(false);
        actions.setSubmitting(false);
        actions.resetForm();
      } else {
        showErrorToast({
          message: 'Failed!',
          description: res.error
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    actions.setSubmitting(false);
  };

  const venueChangeHandler = async (venue_id: number | string) => {
    if (venue_id === 'newVenue') {
      setIsVenueModalVisible(true);
    } else {
      if (values?.venue_id !== venue_id) {
        setFieldValue('venue_contacts', []);
        setFieldValue('parking_additional_information', "");
        setVenueContactsList([]);
      }
      const parkingAdditionalInformation = await fetchVenueDetails(venue_id, setVenueContactsList);
      setFieldValue('venue_id', venue_id);
      setFieldValue('parking_additional_information', parkingAdditionalInformation);
      
    }
  };

  const venueUpdateHandler = (venue_id: number | string, venue_name?: string) => {
    setVenuesList((prevVenueList) => [{ id: venue_id, text: venue_name }, ...prevVenueList]);
    venueChangeHandler(venue_id);
  };

  const handlePointOfContactChange = (event: ContactDetailsType) => {
    const { code, ext, phone } = event;
    setFieldValue(`customer_on_site_country_code`, code);
    setFieldValue(`customer_on_site_extension`, ext);
    setFieldValue(`customer_on_site_phone_number`, phone);

    setFieldTouched(`customer_on_site_country_code`, true);
    setFieldTouched(`customer_on_site_extension`, true);
    setFieldTouched(`customer_on_site_phone_number`, true);
  };

  const createQuoteHandler = async () => {
    try {
      setLoadingCreateQuote(true);

      if (submitForm) {
        const res: any = await services?.['post']?.(`/events/${tabKey}`, {
          ...values,
          from_event: isEvent ?? 1
        });
        if (res?.success) {
          const response = await getEventDetails(res?.data?.data?.id);

          const result = await updateDocumentList({
            documents: [],
            event_id: response?.event_id
          });
          setLoadingCreateQuote(false);
          if (!result.error) {
            showToast({ message: 'Documents Updated Successfully', description: '' });
            navigate(`/events/${response?.event_id}/view`);
          } else {
            setLoadingCreateQuote(false);
            showErrorToast({
              message: 'Error Saving Documents',
              description: result.error || 'Please try again'
            });
          }
        }
      } else {
        const res = await PutTabData(tabKey, {
          ...quoteBasicDetails,
          ...values,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : quoteBasicDetails.unique_invoice_string
        });

        if (res?.success) {
          const tabKey = 'fees';
          const secondRes = await PutTabData(tabKey, {
            id: res?.data?.id,
            quotation_no: res?.data?.quotation_no,
            status: STATUS.ACTIVE,
            unique_invoice_string: random_unique_invoice_string
              ? random_unique_invoice_string
              : res?.data?.unique_invoice_string,
            event_dates: {
              ...values?.event_dates,
              fees: {}
            }
          });

          if (secondRes?.success) {
            navigate(`/quote/view/${secondRes?.data?.id}`);
          } else {
            throw new Error('Failed to create quote.');
          }
        } else {
          formik.setTouched(res?.error);
          throw new Error('Failed to create quote.');
        }
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong!'
      });
    } finally {
      setLoadingCreateQuote(false);
    }
  };

  return (
    <>
      <ScrollToFieldError formik={formik} />
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Label text="Account Manager" isMandatory={true} />
          <SelectInput
            id="account_manager"
            name="account_manager"
            allowClear={false}
            placeholdertitle="Select from the list"
            onChange={(value: any) => {
              setFieldValue('account_manager_id', value);
            }}
            error={touched?.account_manager_id && errors?.account_manager_id}
            value={values.account_manager_id || []}
          >
            {accountManagersList?.map(
              (manager: AccountManagerDropdownObjectType, index: number) => (
                <Select.Option key={index} value={manager?.id}>
                  {manager?.name}
                </Select.Option>
              )
            )}
          </SelectInput>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Label text="Customer Name" isMandatory={true} />
          <SelectInput
            id="customer_id"
            name="customer_id"
            allowClear={false}
            placeholdertitle="Select from the list"
            onChange={(value: any) => {
              const test = customersList?.find((customer) => customer?.id === value);
              const isSame = values?.is_customer_on_site_same_as_customer_primary_contact;

              const updatedValues = {
                ...values,
                customer_on_site_name: isSame ? test?.text : '',
                customer_on_site_email: isSame ? test?.email : '',
                customer_on_site_phone_number: isSame ? test?.phone_number : '',
                customer_on_site_country_code: isSame ? test?.country_code : '',
                customer_on_site_extension: isSame ? test?.extension : ''
              };
              const isNewCustomer = value === 'newCustomer'  
              if(isNewCustomer){
                setIsCustomerModalVisible(true)
              } else {
                setValues(updatedValues);
                setFieldValue('customer_id', value);
              }
            }}
            error={touched?.customer_id && errors?.customer_id}
            value={values.customer_id || []}
          >
            <Select.Option value={'newCustomer'}>+Add Customer</Select.Option>
            {customersList?.map((customer: CustomerDropdownObjectType) => (
              <Select.Option key={customer?.id} value={customer?.id}>
                {customer?.text}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
        <Col md={12} xs={24}>
          <Label text="Event Name" isMandatory={isEvent ? true : false} />
          <Input
            id={'event_name'}
            name={'event_name'}
            placeholder="Enter Event Name"
            error={touched?.event_name && errors?.event_name}
            value={values?.event_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      {/* Additional conatacts */}
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text="Customer On Site Contact" />
          <Input
            name={`customer_on_site_name`}
            id={`customer_on_site_name`}
            placeholder="Type Name"
            value={values?.customer_on_site_name}
            error={touched?.customer_on_site_name && errors?.customer_on_site_name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values?.is_customer_on_site_same_as_customer_primary_contact ? true : false}
          />
        </Col>

        <Col md={6} xs={24}>
          <Label text="On Site Email" />
          <Input
            name={`customer_on_site_email`}
            id={`customer_on_site_email`}
            error={touched?.customer_on_site_email && errors?.customer_on_site_email}
            value={values?.customer_on_site_email}
            onChange={handleChange}
            placeholder="Type Email"
            onBlur={handleBlur}
            disabled={values?.is_customer_on_site_same_as_customer_primary_contact ? true : false}
          />
        </Col>
        <Col md={6} xs={20}>
          <Label text="On Site Phone Number" />
          <InputPhone
            id="customer_on_site_phone_number"
            name="customer_on_site_phone_number"
            value={{
              code: values?.customer_on_site_country_code ?? '+1',
              phone: String(values?.customer_on_site_phone_number ?? '') ?? '',
              ext: String(values?.customer_on_site_extension || '') ?? ''
            }}
            onBlur={handleBlur}
            onChange={handlePointOfContactChange}
            disabled={values?.is_customer_on_site_same_as_customer_primary_contact ? true : false}
          />
        </Col>
      </Row>
      <Checkbox
        name="Same as Primary Customer Contact"
        id="is_customer_on_site_same_as_customer_primary_contact"
        onChange={(e: any) => {
          const isChecked = e.target.checked;
          const test = customersList?.find((customer) => customer?.id === values?.customer_id);

          const updatedValues = {
            ...values,
            customer_on_site_name: isChecked ? test?.text : '',
            customer_on_site_email: isChecked ? test?.email : '',
            customer_on_site_phone_number: isChecked ? test?.phone_number : '',
            customer_on_site_country_code: isChecked ? test?.country_code : '',
            customer_on_site_extension: isChecked ? test?.extension : ''
          };
          setValues(updatedValues);
          handleChange(e);
        }}
        checked={values?.is_customer_on_site_same_as_customer_primary_contact}
      />

      <Row gutter={16} style={{ paddingTop: '15px' }}>
        <Col lg={8} md={12} xs={24}>
          {/* <Label text="Venue" isMandatory={!isEvent ? true : false} /> */}
          <Label text="Venue" isMandatory={false} />
          <SelectInput
            id="venue_id"
            name="venue_id"
            allowClear={false}
            placeholdertitle="Select from the list"
            onChange={(value: number | string) => venueChangeHandler(value)}
            value={values?.venue_id || []}
            error={touched && touched.venue_id && errors && errors.venue_id}
          >
            <Select.Option value={'newVenue'}>+Add Venue</Select.Option>
            {venuesList?.map((venue: VenueDropdownObjectType) => (
              <Select.Option key={venue?.id} value={venue?.id}>
                {venue?.text}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
        <Col lg={8} md={12} xs={24}>
          {/* <Label text="Venue Contact" isMandatory={!isEvent ? true : false} /> */}
          <Label text="Venue Contact" isMandatory={false} />
          <SelectInput
            id="venue_contacts"
            name="venue_contacts"
            // mode="multiple"
            allowClear={false}
            placeholdertitle="Select from the list"
            onChange={(value: any) => {
              setFieldValue('venue_contacts', [value]);
            }}
            error={touched?.venue_contacts && errors?.venue_contacts}
            value={values?.venue_contacts || ''}
            disabled={!venueContactsList?.length}
          >
            {venueContactsList?.map((venueContact: VenueContactsDropdownObjectType) => (
              <Select.Option key={venueContact?.id} value={venueContact?.id}>
                {venueContact?.contact_name}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
        <Col lg={8} md={12} xs={24}>
          <Label text="Staff Entrance And Parking" isMandatory={false} />
          <Input
            name="parking_additional_information"
            id="parking_additional_information"
            placeholder="Additional Info"
            onChange={handleChange}
            value={values?.parking_additional_information || ''}
            error={touched?.parking_additional_information && errors?.parking_additional_information}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Label text="Event Date" isMandatory={false} />
          <DatePickerComponent
            id="event_dates.event_date"
            name="event_dates.event_date"
            format="DD-MMM-YYYY"
            placeholder="DD-MMM-YYYY"
            value={
              !!values?.event_dates?.event_date &&
              values?.event_dates?.event_date !== '0000-00-00' &&
              moment(values?.event_dates?.event_date).format('DD-MMM-YYYY')
            }
            // value={values?.event_dates?.event_date}
            onChange={(value: string) => {
              //since DD-MMM-YYYY is not supported format so we are manually formatting it to YYYY-MM-DD
              const valueToSet = value
                ? moment(convertDateForQuoteDate(value)).format('YYYY-MM-DD')
                : undefined;
              setFieldTouched('event_dates.event_date', true);
              setFieldValue('event_dates.event_date', valueToSet);
            }}
            error={touched?.event_dates?.event_date && errors?.event_dates?.event_date}
            disabledDate={(current: any) => {
              //disable past dates from today
              const yesterdayDate = moment(new Date()).subtract(1, 'days');
              return current && current.valueOf() <= yesterdayDate.valueOf();
            }}
          />
        </Col>
        <Col md={12} xs={24}>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Label text="Start Time" isMandatory={false} />
              <TimePickerComponent
                id="event_dates.start_time"
                name="event_dates.start_time"
                use12Hours={true}
                format="h:mm A"
                placeholder={'HH:MM'}
                value={
                  values?.event_dates?.start_time &&
                  moment(values?.event_dates?.start_time, 'hh:mm A', 'HH:mm')
                }
                onChange={(value: string) => {
                  setFieldTouched('event_dates.start_time', true);
                  setFieldValue('event_dates.start_time', value);
                }}
                onSelect={(e: Moment) => {
                  setFieldValue('event_dates.start_time', e.format('hh:mm A'));
                }}
                error={touched?.event_dates?.start_time && errors?.event_dates?.start_time}
              />
            </Col>

            <Col md={12} xs={24}>
              <Label text="End Time" isMandatory={false} />
              <TimePickerComponent
                id="event_dates.end_time"
                name="event_dates.end_time"
                use12Hours={true}
                format="h:mm A"
                placeholder={'HH:MM'}
                value={
                  values?.event_dates?.end_time &&
                  moment(values?.event_dates?.end_time, 'hh:mm A', 'HH:mm')
                }
                onChange={(value: string) => {
                  setFieldTouched('event_dates.end_time', true);
                  setFieldValue('event_dates.end_time', value);
                }}
                onSelect={(e: Moment) => {
                  setFieldValue('event_dates.end_time', e.format('hh:mm A'));
                }}
                error={touched?.event_dates?.end_time && errors?.event_dates?.end_time}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Guest Details</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '43px',
              height: '1px'
            }}
          />
        </Col>
        <Col md={6} xs={24}>
          <Label text="Adults" isMandatory={false} />
          <Input
            id="adult_count"
            name="adult_count"
            placeholder="0"
            error={touched?.adult_count && errors?.adult_count}
            value={values?.adult_count}
            onChange={(e) => {
              const valueToUpdate: string = e?.target?.value?.replace(/\D/g, '');
              setFieldValue('adult_count', valueToUpdate);
              calculateTotalNumberOfGuestsHandler(
                valueToUpdate,
                values?.kids_count,
                values?.vendor_staff_count
              );
            }}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6} xs={24}>
          <Label text="Kids" isMandatory={false} />
          <Input
            id="kids_count"
            name="kids_count"
            placeholder="0"
            error={touched?.kids_count && errors?.kids_count}
            value={values?.kids_count}
            onChange={(e) => {
              const valueToUpdate: string = e?.target?.value?.replace(/\D/g, '');
              setFieldValue('kids_count', valueToUpdate);
              calculateTotalNumberOfGuestsHandler(
                values?.adult_count,
                valueToUpdate,
                values?.vendor_staff_count
              );
            }}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6} xs={24}>
          <Label text="Vendor Staff" isMandatory={false} />
          <Input
            id="vendor_staff_count"
            name="vendor_staff_count"
            placeholder="0"
            error={touched?.vendor_staff_count && errors?.vendor_staff_count}
            value={values?.vendor_staff_count}
            onChange={(e) => {
              const valueToUpdate: string = e?.target?.value?.replace(/\D/g, '');
              setFieldValue('vendor_staff_count', valueToUpdate);
              calculateTotalNumberOfGuestsHandler(
                values?.adult_count,
                values?.kids_count,
                valueToUpdate
              );
            }}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6} xs={24}>
          <Label text="Total Number of Guests" isMandatory={false} />
          <Input
            id="total_guest"
            name="total_guest"
            disabled={true}
            placeholder="0"
            error={touched?.total_guest && errors?.total_guest}
            value={values?.total_guest}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <AddEditQuoteFooter
        isEvent={isEvent}
        isDraftSubmitting={draftSubmitting}
        isSubmitting={formik?.isSubmitting}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={saveAsDraftHandler}
        nextClickHandler={nextHandler}
        isNextButtonDisabled={false}
        isCreateQuoteButtonVisible={!quoteId}
        createQuoteHandler={createQuoteHandler}
        createQuoteTitle={!isEvent ? 'Create Quote' : 'Create Event'}
        loadingCreateQuote={loadingCreateQuote}
      />
      <AddNewCustomerModal
        isModalVisible={isCustomerModalVisible}
        onCancel={() => {
          setIsCustomerModalVisible(false);
        }}
        onSubmit={addNewCustomerHandler}
      />
      {isVenueModalVisible && (
        <AddNewVenueModal
          isModalVisible={isVenueModalVisible}
          updateVenueList={(venue_id, venue_name) => venueUpdateHandler(venue_id, venue_name)}
          onCancel={() => setIsVenueModalVisible(false)}
        />
      )}
    </>
  );
};

export default CustomerForm;